import { useEffect, useState } from "react";
import useLogBook from "./logBook.hook";
import { MarkerLines, MarkerPoint } from "types/State";
import useContest from "./contest.hook";
import { getContactDetailSelector } from "store/Reducers/logBook.reducer";
import { useSelector } from "react-redux";

const useMap = () => {
  const { logBookContacts, allContacts, allUserContactsData } = useLogBook();
  const { contestContacts, userContestantDetail, userProfile } = useContest();
  const contactDetail = useSelector(getContactDetailSelector);

  const [logBookMarkers, setLogBookMarkers] = useState<MarkerPoint[]>([]);
  const [logBookMarkersLines, setLogBookMarkersLines] = useState<MarkerLines[]>(
    []
  );

  const [allContactMarkers, setAllContactMarkers] = useState<MarkerPoint[]>([]);
  const [allContactMarkersLines, setAllContactBookMarkersLines] = useState<
    MarkerLines[]
  >([]);

  const [allUserContactMarkers, setAllUserContactMarkers] = useState<
    MarkerPoint[]
  >([]);
  const [allUserContactMarkersLines, setAllUserContactBookMarkersLines] =
    useState<MarkerLines[]>([]);

  const [allContestContactMarkers, setAllContestContactMarkers] = useState<
    MarkerPoint[]
  >([]);
  const [allContestContactMarkersLines, setAllContestContactMarkersLines] =
    useState<MarkerLines[]>([]);

  const [userContestMarkers, setUserContestMarkers] = useState<MarkerPoint[]>(
    []
  );
  const [userContestMarkersLines, setUserContestMarkersLines] = useState<
    MarkerLines[]
  >([]);

  const [singleContactMarkers, setSingleContactMarkers] = useState<
    MarkerPoint[]
  >([]);
  const [singleContactMarkerLines, setSingleContactMarkerLines] = useState<
    MarkerLines[]
  >([]);

  useEffect(() => {
    const markers: any[] = [];
    // eslint-disable-next-line array-callback-return
    logBookContacts.forEach((contact) => {
      if (contact.theirCoordinates) {
        markers.push({
          lat: parseFloat(contact.theirCoordinates.latitude),
          lng: parseFloat(contact.theirCoordinates.longitude),
          contactInfo: {
            image: contact?.theirProfilePic,
            name: contact?.theirName,
            country: contact?.theirCountry || contact.country,
            flagCode: contact?.flagCode,
            callSign: contact?.theirCallsign,
            frequency: contact?.frequency,
            mode: contact?.userMode,
            distance: contact?.distance,
          },
        });
      }
      if (contact.myCoordinates) {
        markers.push({
          lat: contact.myCoordinates.latitude,
          lng: contact.myCoordinates.longitude,
          contactInfo: {
            image: contact?.myProfilePic,
            name: userProfile?.firstName,
            country: contact?.myCountry,
            callSign: contact?.profileCallSign || contact?.myCallSign,
            frequency: contact?.frequency,
            mode: contact?.userMode,
            distance: contact?.distance,
          },
        });
      }
    });
    const lines: MarkerLines[] = [];

    // eslint-disable-next-line array-callback-return
    logBookContacts.forEach((contact) => {
      if (contact.theirCoordinates && contact.myCoordinates) {
        lines.push({
          myCallSign: contact?.myCallSign,
          theirCallsign: contact?.theirCallsign,
          myCountry: contact?.myCountry,
          country: contact?.country,
          theirCountry: contact?.theirCountry,
          flagCode: contact?.flagCode,
          start: {
            lat: contact.myCoordinates.latitude,
            lng: contact.myCoordinates.longitude,
          },
          end: {
            lat: parseFloat(contact.theirCoordinates.latitude),
            lng: parseFloat(contact.theirCoordinates.longitude),
          },
        });
      }
    });
    setLogBookMarkers(markers);
    setLogBookMarkersLines(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBookContacts]);

  useEffect(() => {
    const markers: MarkerPoint[] = [];
    // eslint-disable-next-line array-callback-return
    allContacts.forEach((contact) => {
      if (contact.theirCoordinates) {
        markers.push({
          lat: parseFloat(contact.theirCoordinates.latitude),
          lng: parseFloat(contact.theirCoordinates.longitude),
        });
      }
      if (contact.myCoordinates) {
        markers.push({
          lat: contact.myCoordinates.latitude,
          lng: contact.myCoordinates.longitude,
        });
      }
    });
    const lines: MarkerLines[] = [];

    // eslint-disable-next-line array-callback-return
    allContacts.forEach((contact) => {
      if (contact.theirCoordinates && contact.myCoordinates) {
        lines.push({
          myCallSign: contact?.myCallSign,
          theirCallsign: contact?.theirCallsign,
          myCountry: contact?.myCountry,
          country: contact?.country,
          theirCountry: contact?.theirCountry,
          flagCode: contact?.flagCode,
          start: {
            lat: contact.myCoordinates.latitude,
            lng: contact.myCoordinates.longitude,
          },
          end: {
            lat: parseFloat(contact.theirCoordinates.latitude),
            lng: parseFloat(contact.theirCoordinates.longitude),
          },
        });
      }
    });
    setAllContactMarkers(markers);
    setAllContactBookMarkersLines(lines);
  }, [allContacts]);

  useEffect(() => {
    const markers: any[] = [];
    // eslint-disable-next-line array-callback-return
    // console.log("allUserContactsData : ", allUserContactsData)
    allUserContactsData.forEach((contact) => {
      if (contact.theirCoordinates) {
        markers.push({
          lat: parseFloat(contact.theirCoordinates.latitude),
          lng: parseFloat(contact.theirCoordinates.longitude),
          isMyLocation: false,
          contactInfo: {
            image: contact?.theirProfilePic,
            name: contact?.theirName,
            country: contact?.theirCountry || contact.country,
            flagCode: contact?.flagCode,
            callSign: contact?.theirCallsign,
            frequency: contact?.frequency,
            mode: contact?.userMode,
            distance: contact?.distance,
          },
        });
      }
      if (contact.myCoordinates) {
        markers.push({
          lat: contact.myCoordinates.latitude,
          lng: contact.myCoordinates.longitude,
          isMyLocation: true,
          contactInfo: {
            image: contact?.myProfilePic,
            name: contact?.myName || userProfile?.firstName,
            country: contact?.myCountry,
            callSign: contact?.profileCallSign || contact?.myCallSign,
            frequency: contact?.frequency,
            mode: contact?.userMode,
            distance: contact?.distance,
          },
        });
      }
    });
    const lines: MarkerLines[] = [];

    // eslint-disable-next-line array-callback-return
    allUserContactsData.forEach((contact) => {
      if (contact.theirCoordinates && contact.myCoordinates) {
        lines.push({
          myCallSign: contact?.myCallSign,
          theirCallsign: contact?.theirCallsign,
          myCountry: contact?.myCountry,
          country: contact?.country,
          theirCountry: contact?.theirCountry,
          flagCode: contact?.flagCode,
          start: {
            lat: contact.myCoordinates.latitude,
            lng: contact.myCoordinates.longitude,
          },
          end: {
            lat: parseFloat(contact.theirCoordinates.latitude),
            lng: parseFloat(contact.theirCoordinates.longitude),
          },
        });
      }
    });
    setAllUserContactMarkers(markers);
    setAllUserContactBookMarkersLines(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserContactsData]);

  useEffect(() => {
    if (contestContacts) {
      const markers: any[] = [];
      // eslint-disable-next-line array-callback-return
      // console.log("allUserContactsData : ", allUserContactsData)
      contestContacts.forEach((contact) => {
        if (contact.theirCoordinates) {
          markers.push({
            lat: parseFloat(contact.theirCoordinates.latitude),
            lng: parseFloat(contact.theirCoordinates.longitude),
            contactInfo: {
              image: contact?.theirProfilePic,
              name: contact?.theirName,
              country: contact?.theirCountry || contact.country,
              flagCode: contact?.flagCode,
              callSign: contact?.theirCallsign,
              frequency: contact?.frequency,
              mode: contact?.userMode,
              distance: contact?.distance,
            },
          });
        }
        if (contact.myCoordinates) {
          markers.push({
            lat: contact.myCoordinates.latitude,
            lng: contact.myCoordinates.longitude,
            contactInfo: {
              image: contact?.myProfilePic,
              name: userProfile?.firstName,
              country: contact?.myCountry,
              callSign: contact?.profileCallSign || contact?.myCallSign,
              frequency: contact?.frequency,
              mode: contact?.userMode,
              distance: contact?.distance,
            },
          });
        }
      });
      const lines: MarkerLines[] = [];

      // eslint-disable-next-line array-callback-return
      contestContacts.forEach((contact) => {
        if (contact.theirCoordinates && contact.myCoordinates) {
          lines.push({
            myCallSign: contact?.myCallSign,
            theirCallsign: contact?.theirCallsign,
            myCountry: contact?.myCountry,
            country: contact?.country,
            theirCountry: contact?.theirCountry,
            flagCode: contact?.flagCode,
            start: {
              lat: contact.myCoordinates.latitude,
              lng: contact.myCoordinates.longitude,
            },
            end: {
              lat: parseFloat(contact.theirCoordinates.latitude),
              lng: parseFloat(contact.theirCoordinates.longitude),
            },
          });
        }
      });
      setAllContestContactMarkers(markers);
      setAllContestContactMarkersLines(lines);
    }
  }, [contestContacts]);

  useEffect(() => {
    if (userContestantDetail?.userLogs) {
      const markers: any[] = [];
      // eslint-disable-next-line array-callback-return
      // console.log("allUserContactsData : ", allUserContactsData)
      userContestantDetail?.userLogs.forEach((contact) => {
        if (contact.theirCoordinates) {
          markers.push({
            lat: parseFloat(contact.theirCoordinates.latitude),
            lng: parseFloat(contact.theirCoordinates.longitude),
            contactInfo: {
              image: contact?.theirProfilePic,
              name: contact?.theirName,
              country: contact?.theirCountry || contact.country,
              flagCode: contact?.flagCode,
              callSign: contact?.theirCallsign,
              frequency: contact?.frequency,
              mode: contact?.userMode,
              distance: contact?.distance,
            },
          });
        }
        if (contact.myCoordinates) {
          markers.push({
            lat: contact.myCoordinates.latitude,
            lng: contact.myCoordinates.longitude,
            contactInfo: {
              image: contact?.myProfilePic,
              name: contact?.myName || userProfile?.firstName,
              country: contact?.myCountry,
              callSign: contact?.profileCallSign || contact?.myCallSign,
              frequency: contact?.frequency,
              mode: contact?.userMode,
              distance: contact?.distance,
            },
          });
        }
      });
      const lines: MarkerLines[] = [];

      // eslint-disable-next-line array-callback-return
      userContestantDetail?.userLogs.forEach((contact) => {
        if (contact.theirCoordinates && contact.myCoordinates) {
          lines.push({
            myCallSign: contact?.myCallSign,
            theirCallsign: contact?.theirCallsign,
            myCountry: contact?.myCountry,
            country: contact?.country,
            theirCountry: contact?.theirCountry,
            flagCode: contact?.flagCode,
            start: {
              lat: contact.myCoordinates.latitude,
              lng: contact.myCoordinates.longitude,
            },
            end: {
              lat: parseFloat(contact.theirCoordinates.latitude),
              lng: parseFloat(contact.theirCoordinates.longitude),
            },
          });
        }
      });
      setUserContestMarkers(markers);
      setUserContestMarkersLines(lines);
    }
  }, [userContestantDetail]);

  useEffect(() => {
    if (contactDetail) {
      const markers: any[] = [];
      // eslint-disable-next-line array-callback-return
      // console.log("allUserContactsData : ", allUserContactsData)
      if (contactDetail.theirCoordinates) {
        markers.push({
          lat: parseFloat(contactDetail.theirCoordinates.latitude),
          lng: parseFloat(contactDetail.theirCoordinates.longitude),
          contactInfo: {
            image: contactDetail?.theirProfilePic,
            name: contactDetail?.theirName,
            country: contactDetail?.theirCountry || contactDetail.country,
            flagCode: contactDetail?.flagCode,
            callSign: contactDetail?.theirCallsign,
            frequency: contactDetail?.frequency,
            mode: contactDetail?.userMode,
            distance: contactDetail?.distance,
          },
        });
      }
      if (contactDetail.myCoordinates) {
        markers.push({
          lat: contactDetail.myCoordinates.latitude,
          lng: contactDetail.myCoordinates.longitude,
          contactInfo: {
            image: contactDetail?.myProfilePic,
            name: contactDetail?.myName || userProfile?.firstName,
            country: contactDetail?.myCountry,
            callSign: contactDetail?.profileCallSign || contactDetail?.myCallSign,
            frequency: contactDetail?.frequency,
            mode: contactDetail?.userMode,
            distance: contactDetail?.distance,
          },
        });
      }
      const lines: MarkerLines[] = [];

      // eslint-disable-next-line array-callback-return
      if (contactDetail.theirCoordinates && contactDetail.myCoordinates) {
        lines.push({
          myCallSign: contactDetail?.myCallSign,
          theirCallsign: contactDetail?.theirCallsign,
          myCountry: contactDetail?.myCountry,
          country: contactDetail?.country,
          theirCountry: contactDetail?.theirCountry,
          start: {
            lat: contactDetail.myCoordinates.latitude,
            lng: contactDetail.myCoordinates.longitude,
          },
          end: {
            lat: parseFloat(contactDetail.theirCoordinates.latitude),
            lng: parseFloat(contactDetail.theirCoordinates.longitude),
          },
        });
      }
      setSingleContactMarkers(markers);
      setSingleContactMarkerLines(lines);
    }
  }, [contactDetail]);

  return {
    logBookMarkers,
    logBookMarkersLines,
    allContactMarkers,
    allContactMarkersLines,
    allUserContactMarkers,
    allUserContactMarkersLines,
    allContestContactMarkers,
    allContestContactMarkersLines,
    userContestMarkers,
    userContestMarkersLines,
    singleContactMarkers,
    singleContactMarkerLines,
  };
};

export default useMap;
