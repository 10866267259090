import { SpottingState } from "types/State";
import { RootState } from "store/Reducers";
import { SpottingPayload, SET_SPOT, SET_SPOT_FILTERS, SET_SPOT_SORT } from "store/Actions/spotting.action";
import { SortConfig, SpotFilters } from "types/Models";
const defaultState: SpottingState = {
  spots: [],
  filters: [],
  sort: { column: 'timestamp', order: 'desc' },
};

export default function spottingReducer(
  state = defaultState,
  action: SpottingPayload
) {
  switch (action.type) {
    case SET_SPOT:
      return {
        ...state,
        spots: action.payload,
      };
    case SET_SPOT_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_SPOT_SORT:
      return {
        ...state,
        sort: action.payload,
      };

    default:
      return state;
  }
}

// GET STATE
export const getSpotSelector = (state: RootState) => {
  return state.spotting.spots || [];
};

// Selector to get the current filters
export const getSpotFiltersSelector = (state: RootState): SpotFilters[] => state.spotting.filters || [];

// Selector to get the current sort configuration
export const getSpotSortSelector = (state: RootState): SortConfig => state.spotting.sort || { column: 'timestamp', order: 'desc' };
