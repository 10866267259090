import React, { Fragment, useEffect, useMemo, useState } from "react";
import ActivityFilter from "components/ActivityFilter";
import Header from "components/Header";
import { ActivityFilterList } from "constants/Config";
import {
  GoogleMap,
  useLoadScript,
  PolylineF,
  GoogleMapProps,
  MarkerClusterer,
  MarkerF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { mapStylesDark } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import useActivity from "hooks/activity.hook";
import { useSelector } from "react-redux";
import { getActivitiesMapSelector } from "store/Reducers/pota.reducer";
import ActivityMarker from "components/ActivityMarker";
import { getIsActivitiesMapLoadingSelector } from "store/Reducers/loading.reducer";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import Icons from "assets/Icons";
import customMarkerIcon from "../../../assets/Icons/memberMarker.svg";

const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];
function Activities() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });
  const activitiesMap = useSelector(getActivitiesMapSelector);
  const isActivitiesLoading = useSelector(getIsActivitiesMapLoadingSelector);

  const {
    getUserLocation,
    center,
    debouncedBoundChangeListener,
    onLoad,
    activityFilter,
    setActivityFilter,
    onCreateLogbook,
    userCoordinate,
    setInfoWindowOpen,
    debouncedDragListener,
    zoomLevel,
  } = useActivity();
  const mapRef = React.useRef<any>();

  useEffect(() => {
    if (isLoaded) {
      getUserLocation();
    }
  }, [isLoaded]);

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  return (
    <main className="relative w-full h-full">
      <Header title="Logbook" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="glassmorphism w-full h-fit pb-6">
          <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
            <div className="flex flex-col sm:flex-row gap-2 md:gap-10 lg:gap-10 xl:gap-10 2xl:gap-10">
              <p className="font-[play] font-bold text-lg text-white">
                POTA AND ACTIVITY <span className="text-[#17F9DA]">SITES</span>
              </p>
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-3">
            <div className="flex px-7 flex-wrap">
              {activityFilter.map((filter, index) => (
                <ActivityFilter
                  activity={filter}
                  index={index}
                  setActivityFilter={setActivityFilter}
                />
              ))}
            </div>
            {isActivitiesLoading ? (
              <div className="h-[30px]">
                <ActivityIndicator size={14} />
              </div>
            ) : null}
          </div>
          <div className="w-full h-[75vh] relative mt-3">
            {!isLoaded && !center ? (
              <RouteLoading />
            ) : (
              <>
                <GoogleMap
                  ref={mapRef}
                  mapContainerClassName="map-container no-close-button-on-info-windows"
                  center={center}
                  onLoad={onLoad}
                  onBoundsChanged={debouncedBoundChangeListener}
                  onDrag={debouncedDragListener}
                  zoom={10}
                  options={{
                    styles: [
                      ...mapStylesDark,
                      {
                        featureType: "poi", // Points of Interest (landmarks, etc.)
                        elementType: "all",
                        stylers: [{ visibility: "off" }], // Hide all POIs
                      },
                    ],
                  }}
                >
                  <MarkerClustererF options={options}>
                    {(clusterer) => (
                      <Fragment>
                        {activitiesMap.map((activity, index) => (
                          <ActivityMarker
                            key={`${activity.latitude}-${activity?.longitude}-${index}`}
                            activity={activity}
                            index={index}
                            onCreateLogbook={onCreateLogbook}
                            clusterer={zoomLevel >= 10 ? null : clusterer} // if user is zoomed in we dont want to cluster
                            setInfoWindowOpen={setInfoWindowOpen}
                          />
                        ))}
                      </Fragment>
                    )}
                  </MarkerClustererF>

                  {userCoordinate ? (
                    <MarkerF
                      position={{
                        lat: userCoordinate?.latitude,
                        lng: userCoordinate?.longitude,
                      }}
                      icon={{
                        url: customMarkerIcon,
                        scaledSize: new window.google.maps.Size(40, 40),
                      }}
                    ></MarkerF>
                  ) : null}
                </GoogleMap>
                <div className="flex justify-center relative">
                  <div
                    className="bg-[#010A41] px-4 py-1 rounded-xl border-[0.5px] border-white absolute top-[-60px] flex items-center cursor-pointer"
                    onClick={getUserLocation}
                  >
                    <img
                      src={Icons.Location}
                      alt=""
                      className="w-[17px] h-[17px] mr-3"
                    />
                    <h3 className="font-[Play] text-white font-bold">
                      Find My Location
                    </h3>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Activities;
