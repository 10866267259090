import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { Form, Formik, useFormikContext } from "formik";
import TitledInput from "components/Inputs/TitledInput";
import usePota from "hooks/pota.hook";
import { useDebouncedCallback } from "use-debounce";
import {
  Activities,
  LogBookContactModel,
  LogbookSettingsForm,
  ParkReferenceModel,
} from "types/Models";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { Tooltip } from "components/Tooltip";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { Templates } from "constants/Config";
import {
  getCloseActivityIcon,
  getParkTypeName,
  getPrimaryActivity,
  getUniqueActivities,
  getUpdatedActivitiesData,
  suggestionsFormatter,
  updateMyParks,
  validateNumberOfSelectedActivities,
} from "helpers/Utils";
import { ActivitiesColorMapping } from "../../../../../constants/index";
import { HorizontalDivider } from "components/Divider";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { setNearbyParkReferencesAction } from "store/Actions/pota.actions";
var uniq = require("lodash.uniq");

type ModalProps = {
  onClose: () => void;
  settingDisplay?: boolean;
  selectedContact?: LogBookContactModel;
};

export const PotaModal: FC<ModalProps> = ({
  onClose,
  settingDisplay,
  selectedContact,
}) => {
  const { selectedLogbook, saveLogbookSettings } = useLogbookSetting();
  const { values, setValues } = useLogBookSettingsForm();
  const { values: editContactValues, setValues: updateEditContactValues } =
    useFormikContext<any>();

  // we are reusing this modal for logbook settings and contact settings so depending on the selected contact or logbook we will set the initial data
  const [dataSource, setDataSource] = useState<any>({});
  useEffect(() => {
    if (selectedContact) {
      setDataSource(selectedContact);
    } else {
      setDataSource(selectedLogbook);
    }
  }, [selectedContact, selectedLogbook]);

  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    getNearbyParkReferences,
    nearbyParkReferencesData,
    setNearbyParkReferences,
  } = usePota();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [parksAdded, setParksAdded] = useState<boolean>(false);
  const [activities, setActivities] = useState<any>([]);

  const [activityOptions, setActivityOptions] = useState<any>([
    Activities.POTA,
    Activities.WWFF,
    Activities.IOTA,
    Activities.SOTA,
  ]);
  //keeps track of the current selected activity for the field
  const [activeActivity, setActiveActivity] = useState<string | null>(null);

  useEffect(() => {
    const existingActivitiesOnLogbook = dataSource?.activities || [];

    //remove existing activities from the activities array
    const filteredActivities = activityOptions.filter(
      (activity: string) => !existingActivitiesOnLogbook.includes(activity)
    );

    setActivityOptions(filteredActivities);
  }, [dataSource]);
  useEffect(() => {
    //specific where we are using this modal for logbook settings
    if (settingDisplay) {
      setValues({
        ...values,
        ...formData,
      });
    }
  }, [formData]);

  useEffect(() => {
    if (
      (Object.keys(dataSource?.myParks || {}).length ||
        (dataSource?.activitiesData &&
          dataSource?.activitiesData?.length !== 0)) &&
      !parksAdded
    ) {
      setFormData((prev: any) => ({
        ...prev,
        myParks: dataSource?.myParks,
        //backward compatibility to support old logbooks with myParks data
        activitiesData:
          dataSource?.primaryActivity || dataSource?.activitiesData
            ? dataSource?.activitiesData
            : Object.keys(dataSource?.myParks || {}),
        primaryActivity: dataSource?.primaryActivity,
        activities: dataSource?.activities,
      }));
      setParksAdded(true);
    }

    if (dataSource?.primaryActivity || dataSource?.activities) {
      setActivities(dataSource?.activities);
    } else if (
      dataSource?.logbookStyle === Templates.POTA &&
      dataSource?.myParks
    ) {
      //backward compatibility
      setActivities([Activities.POTA]);
    }
  }, [dataSource]);

  const onSaveClickHandler = () => {
    //if modal is being used to edit a contact instead of logbook settings
    if (selectedContact) {
      updateEditContactValues((prev: any) => ({
        ...prev,
        activitiesData: formData?.activitiesData,
        activities: uniq(
          formData?.activitiesData.map((activity: any) => activity.type)
        ),
      }));
    } else {
      //if component is being used on logbook settings
      const editLogBookData: LogbookSettingsForm = {
        myParks: formData?.myParks || {},
        activitiesData: formData?.activitiesData || [],
      };
      if (formData?.activitiesData && formData?.activitiesData?.length) {
        editLogBookData.activities = uniq(
          formData.activitiesData.map((activity: any) => {
            if (!activity.type) {
              return Activities.POTA;
            } else {
              return activity.type;
            }
          })
        );
        editLogBookData.activitiesReferences = formData.activitiesData.map(
          (activity: any) => activity.reference
        );
      }

      saveLogbookSettings(editLogBookData);
    }

    onClose();
  };

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString, activityType) =>
      getParReferenceSuggestions(searchString, activityType),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString, activityType) =>
      getParkByReference(searchString, activityType),
    200
  );

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const showParkSuggestions = (
    key: string,
    setValues: any,
    activity: string
  ) => {
    if (!showSuggestions || activeActivity !== activity) return {};

    return {
      suggestions:
        formData?.[key] && parkReferenceSuggestionsData?.[key]
          ? parkReferenceSuggestionsData?.[key]
          : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        // Check if activities numbers are valid
        if (
          !validateNumberOfSelectedActivities(
            suggestion?.type || Activities.POTA,
            formData
          )
        ) {
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);

        setValues((prev: any) => {
          const updatedMyParks = updateMyParks(prev, suggestion); // Update 'myParks' with the new suggestion.

          return {
            ...prev,
            myParks: updatedMyParks,
            myPark: "", // Reset the 'myPark' field.
            activitiesData: formData?.activitiesData?.length
              ? [...formData.activitiesData, { ...suggestion, type: activity }]
              : [{ ...suggestion, type: activity }],
          };
        });

        // set formdata
        setFormData({
          ...formData,
          activitiesData: formData?.activitiesData?.length
            ? [...formData.activitiesData, { ...suggestion, type: activity }]
            : [{ ...suggestion, type: activity }],
        });
        setNearbyParkReferences(null);
        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const findMyParkHandler = async (activity?: string) => {
    // TODO: pass these coordinates into getNearbyparksreference function
    const coor = await fetchCoordinates();

    if (coor?.latitude && coor?.longitude) {
      getNearbyParkReferences([coor?.latitude, coor?.longitude], activity);
      setShowSuggestions(true);
    }
  };
  const renderSelectedParks = (setValues: any, activity: string) => {
    if (
      Object.keys(formData?.myParks || {}).length === 0 &&
      (!formData?.activitiesData || formData.activitiesData.length === 0)
    ) {
      return null;
    }

    const removePark = (key: string) => {
      if (dataSource?.primaryActivity || dataSource?.activities) {
        // remove park from formData.activitiesData array by reference
        const filteredActivitiesData = formData.activitiesData.filter(
          (item: any) => item.reference !== key
        );
        setFormData((prev: any) => ({
          ...prev,
          activitiesData: filteredActivitiesData,
        }));
        // also update formik
        setValues((prev: any) => ({
          ...prev,
          activitiesData: filteredActivitiesData,
        }));
      } else {
        //backward compatibility
        //remove park from form data as well as key
        setFormData((prev: any) => {
          const { [key]: omit, ...rest } = prev.myParks;

          return { ...prev, myParks: rest };
        });
        // also update formik
        setValues((prev: any) => {
          const { [key]: omit, ...rest } = prev.myParks;
          return { ...prev, myParks: rest };
        });
      }
    };

    const filteredAcitvity = formData?.activitiesData?.filter(
      (item: any) => item.type === activity
    );
    return (
      <div className="flex flex-row text-white text-[11px] gap-2 -mt-[10px] flex-wrap">
        {filteredAcitvity?.map((data: any, index: number) => (
          <div
            key={index}
            className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
          >
            <Tooltip
              message={`${data?.reference} - ${
                data?.parktypeDesc || data?.regionName || data?.name
              }`}
              className="w-[180px]"
            >
              <div>{`${data?.reference}`}</div>
            </Tooltip>

            <img
              src={Icons.CrossIcon}
              className="h-3 w-3"
              alt="close"
              onClick={() => removePark(data.reference)}
            />
          </div>
        ))}
      </div>
    );
  };

  const onActivityClose = (activity: string) => {
    //remove activity from activities array
    const filteredActivities = activities.filter(
      (item: string) => item !== activity
    );
    setActivities(filteredActivities);
    //remove park from form data and update activitiesData and activities
    setFormData({
      ...formData,
      activitiesData: formData?.activitiesData?.filter(
        (act: any) => act.type !== activity
      ),
      activities: formData?.activities?.filter((act: any) => act !== activity),
    });
    setFormData((prev: any) => ({
      ...prev,
      activitiesData: prev?.activitiesData?.filter(
        (act: any) => act.type !== activity
      ),
      activities: prev?.activities?.filter((act: any) => act !== activity),
    }));
    setActivityOptions([...activityOptions, activity]);
  };

  return (
    <section
      className={
        settingDisplay
          ? ""
          : "absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[14%] overflow-scroll"
      }
    >
      <div className=" w-full ">
        <div className="w-full ">
          <div
            className={
              settingDisplay
                ? "text-white"
                : "flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[10%] md:px-[6%] py-[10%] gap-8"
            }
          >
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col gap-4 w-full ">
                <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-start mb-6 ">
                  <div className="w-[100%]">
                    <div className="mb-5">
                      <p className="font-[Barlow] font-bold mb-3">
                        Add Activities
                      </p>
                      <div className="flex">
                        {activityOptions?.map(
                          (activity: string, index: number) => (
                            <div
                              className="mr-2 cursor-pointer"
                              onClick={() => {
                                //add activity to activities array
                                setActivities([...activities, activity]);
                                //remove activity from activityOptions array
                                const filteredActivities =
                                  activityOptions.filter(
                                    (item: string) => item !== activity
                                  );
                                setActivityOptions(filteredActivities);
                              }}
                            >
                              <img
                                src={getCloseActivityIcon(activity)}
                                alt=""
                                width={90}
                              />
                            </div>
                          )
                        )}
                      </div>
                      {/* //hr line */}
                    </div>
                    <hr className="w-full border-[#fff] opacity-10 border-0.5 mb-5" />

                    <Formik
                      initialValues={{}}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                      }}
                    >
                      <Form>
                        {activities?.map((activity: string, index: number) => (
                          <>
                            <div className="flex flex-col mb-6">
                              <div className="flex justify-between">
                                <div className="w-[60%]">
                                  <TitledInput
                                    activityType={activity}
                                    allCaps
                                    setText={(e, activityType) => {
                                      if (activityType === activity) {
                                        setFormData({
                                          ...formData,
                                          myPark: e.target.value
                                            .toUpperCase()
                                            .trim(),
                                        });

                                        if (e.target.value.length > 1) {
                                          debouncedParkReferenceChangeHandler(
                                            {
                                              key: "myPark",
                                              value: e.target.value
                                                .toUpperCase()
                                                .trim(),
                                            },
                                            activity
                                          );
                                        }
                                        // reset park data on change
                                        setParkByReference({
                                          key: "myPark",
                                          value: null,
                                        });
                                      }
                                    }}
                                    onBlur={() => {

                                      if (formData.myPark) {
                                        debouncedGetParkByReference(
                                          {
                                            value: formData.myPark,
                                            key: "myPark",
                                          },
                                          activity
                                        );
                                      }

                                      // remove suggestions on blur
                                      resetParkReferenceSuggestions("myPark");
                                      setNearbyParkReferences(null);

                                      // hide suggestions
                                      hideParkSuggestions();
                                      setTimeout(
                                        () => setActiveActivity(null),
                                        200
                                      );
                                    }}
                                    onFocus={() => {

                                      setActiveActivity(activity);
                                      setShowSuggestions(true);
                                      setTimeout(() => {
                                        setNearbyParkReferences(null);
                                      }, 200);
                                    }}
                                    isLoading={
                                      !!isParkReferenceDataLoading?.myPark
                                    }
                                    name="myPark"
                                    title={`Select Your ${getParkTypeName(
                                      activity
                                    )} ${
                                      activity === Activities.POTA
                                        ? "(s):"
                                        : ":"
                                    }`}
                                    placeHolder={`Type the name of the ${getParkTypeName(
                                      activity
                                    )} here`}
                                    width="w-full"
                                    tabindex={15}
                                    // value={formData?.myPark}
                                    containerClass={"m-0"}
                                    {...showParkSuggestions(
                                      "myPark",
                                      setFormData,
                                      activity
                                    )}
                                    className={"h-10"}
                                    onActivityClose={() =>
                                      onActivityClose(activity)
                                    }
                                  />
                                </div>
                                <div className="w-[30%]">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      resetParkReferenceSuggestions("myPark");
                                      setNearbyParkReferences(null);

                                      setActiveActivity(activity);

                                      findMyParkHandler(activity);
                                    }}
                                    className="text-play text-white rounded-full px-4 py-1.5 border-2 mt-6"
                                    style={{
                                      borderColor:
                                        ActivitiesColorMapping[activity],
                                    }}
                                  >
                                    Find My {getParkTypeName(activity)}
                                  </button>
                                </div>
                              </div>
                              <div className="w-[60%] flex-wrap">
                                {renderSelectedParks(setFormData, activity)}
                              </div>
                            </div>
                          </>
                        ))}
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            {settingDisplay ? null : (
              <>
                <div className="flex w-full justify-center">
                  <BorderButtonSecondary
                    // isLoading={loading || logbookSettingLoading}
                    text={"Save"}
                    onClick={onSaveClickHandler}
                    childClassName="text-sm"
                    className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
                  />
                </div>
                <div
                  className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
                  onClick={onClose}
                >
                  <img
                    src={Icons.GlowingClose}
                    className="h-8 w-8"
                    alt="close"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
