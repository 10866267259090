import { Timestamp } from "firebase/firestore";
import {
  RESET_USER_ACTIVITIES,
  SET_USER_ACTIVITIES,
} from "store/Actions/userActivity.actions";
import { RootState } from "store/Reducers";
import {
  defaultUserActivityState,
  UserActivityPayload,
  UserActivityState,
} from "types/State";

// Function to check if event happened 12 hours before now
function isEventOlderThan12Hours(eventTimestamp: Timestamp): boolean {
  // Get the current time in seconds
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  // Calculate the timestamp of 12 hours ago
  const twelveHoursInSeconds = 12 * 60 * 60;
  const twelveHoursAgoInSeconds = currentTimeInSeconds - twelveHoursInSeconds;

  // Compare the event timestamp to the calculated time
  return eventTimestamp?.seconds < twelveHoursAgoInSeconds;
}

export default function userActivityReducer(
  state: UserActivityState = defaultUserActivityState,
  action: UserActivityPayload
): UserActivityState {
  switch (action.type) {
    case SET_USER_ACTIVITIES:
      const userActivities = action.payload;
      let status = "Complete";
      let activityType = "";
      let activityId = "";
      let showExportAllContacts = true;

      if (userActivities && Array.isArray(userActivities) && userActivities.length) {
        userActivities.forEach((activity: any) => {
          if (activity?.status === "In Progress") {
            status = "In Progress";
            activityType = activity?.operationType;
            activityId = activity?.id;
          }
  
          if (
            activity?.operationType === "exportAllContacts" &&
            activity?.timestamp &&
            !isEventOlderThan12Hours(activity?.timestamp)
          ) {
            showExportAllContacts = false;
          }
        });
      }

      return {
        ...state,
        userActivities,
        status,
        activityType,
        activityId,
        showExportAllContacts,
      };
    case RESET_USER_ACTIVITIES:
      return {
        ...state,
        ...defaultUserActivityState,
      };
    default:
      return state;
  }
}

export const getUserActivitiesSelector = (state: RootState) => {
  return state.userActivity.userActivities;
};

export const getUserActivityStatusSelector = (state: RootState) => {
  return state.userActivity.status;
};

export const getUserActivityTypeSelector = (state: RootState) => {
  return state.userActivity.activityType;
};

export const getShowExportAllContactsSelector = (state: RootState) => {
  return state.userActivity.showExportAllContacts;
};

export const getUserActivityIdSelector = (state: RootState) => {
  return state.userActivity.activityId;
};