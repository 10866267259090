import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { Field } from "formik";
import { getCloseActivityIcon } from "helpers/Utils";
import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { TitledInputProps } from "types/Component";

function TitledInput({
  placeHolder,
  name,
  type,
  value,
  setText,
  className,
  width,
  title,
  error,
  props,
  tabindex,
  info,
  onBlur,
  onFocus,
  isLoading,
  status,
  statusBg,
  containerClass,
  suggestions,
  suggestionsFormatter,
  onSuggestionClick,
  activityType,
  onActivityClose,
  allCaps,
}: TitledInputProps) {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center w-full">
        <div className="flex items-center">
          <p className="font-[Barlow] font-bold text-sm text-white truncate">
            {title}
          </p>
          {activityType ? (
            <div className="ml-2 flex cursor-pointer" onClick={onActivityClose}>
              <img src={getCloseActivityIcon(activityType)} alt="" width={60} />
              <img
                className="cursor-pointer"
                src={Icons.CloseWhite}
                alt=""
                width={25}
              />
            </div>
          ) : null}
        </div>
        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <Field
        className={`h-8 bg-[#23234C] px-3 font-[Barlow] text-sm text-white mt-1.5 w-full ${
          className ? className : ""
        } ${error ? "border border-orange-700 border-solid" : ""}`}
        onChange={(e: any) => setText(e, activityType)}
        type={type ? type : "text"}
        name={name}
        onInput={(e: any) => {
          if (allCaps) {
            e.target.value = e.target.value.toUpperCase();
          }
        }}
        id=""
        onBlur={onBlur ? onBlur : () => {}}
        placeholder={placeHolder}
        value={value}
        tabIndex={tabindex}
        onFocus={onFocus ? onFocus : () => {}}
        {...props}
      />

      {suggestions && suggestions.length > 0 ? ( // Show suggestions dropdown if suggestions exist
        <div className="absolute top-[100%-3] left-0 right-0 z-[1000] bg-[#10103C] shadow-lg font-[Barlow] text-sm h-[200px] overflow-scroll">
          {suggestions.map((suggestion: any, index: number) => (
            <div
              key={index}
              className="px-[10px] py-2 cursor-pointer hover:bg-white hover:bg-opacity-[.1] text-indigo-50 border border-sky-200 border-opacity-20 truncate"
              onClick={
                onSuggestionClick
                  ? () => onSuggestionClick(suggestion)
                  : () => {}
              }
            >
              {suggestionsFormatter ? suggestionsFormatter(suggestion) : ""}
            </div>
          ))}
        </div>
      ) : null}

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
      <div className="flex justify-between items-center h-[20px]">
        {status && !isLoading ? (
          <div
            className={`${
              statusBg ? statusBg : ""
            } text-[9px] font-[Barlow] font-semibold text-white px-2 py-[1px] rounded-full`}
          >
            {status}
          </div>
        ) : null}
        <div className="w-[32px] h-[20px] flex justify-end absolute right-0">
          {isLoading ? <ActivityIndicator size={8} /> : null}
        </div>
      </div>
    </div>
  );
}

export default TitledInput;
