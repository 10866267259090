import createSagaMiddleware, { END } from "@redux-saga/core";
import { applyMiddleware, createStore } from "redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./Reducers";
import { SagaStore, StoreConfig } from "types/State";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["loading", "config", "guest"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(): StoreConfig {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = applyMiddleware(sagaMiddleware);
  const composeEnhancers = composeWithDevTools({});
  const enhancedMiddleware = composeEnhancers(middleware);
  const store = createStore(
    persistedReducer,
    enhancedMiddleware
  ) as unknown as SagaStore;
  const persistor = persistStore(store);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return { persistor, store };
}
