import { SpotFilters, SpotModel, SortConfig } from "types/Models";

export const CREATE_SPOT = "CREATE_SPOT";
export const EDIT_SPOT = "EDIT_SPOT";

export const FETCH_SPOT = "FETCH_SPOT";
export const SET_SPOT = "SET_SPOT";
export const SET_SPOT_FILTERS = 'SET_SPOT_FILTERS';
export const SET_SPOT_SORT = 'SET_SPOT_SORT';

export interface SpottingPayload {
  type: string;
  payload?: any;
}

export function createSpot(data: SpotModel): SpottingPayload {
  return {
    type: CREATE_SPOT,
    payload: data,
  };
}

export function editSpot(data: any): SpottingPayload {
  return {
    type: EDIT_SPOT,
    payload: data,
  };
}

export function fetchSpot(filters?: SpotFilters[], loading?: boolean, sort?: SortConfig): SpottingPayload {
  return {
    type: FETCH_SPOT,
    payload: {
      filters, 
      loading: loading === undefined ? true : false, 
      sort,
    },
  };
}

export function setSpot(): SpottingPayload {
  return {
    type: SET_SPOT,
  };
}

// Action to set spot filters
export function setSpotFilters(filters: SpotFilters[]): SpottingPayload {
  return {
    type: SET_SPOT_FILTERS,
    payload: filters,
  };
}

// Action to set sort configuration
export function setSpotSort(sort: SortConfig): SpottingPayload {
  return {
    type: SET_SPOT_SORT,
    payload: sort,
  };
}
