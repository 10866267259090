import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableDropDown from "components/Table/Table/TableDropDown";
import {
  ADILogBookTableDropDowns,
  LogBookTableDropDowns,
} from "constants/ArrayVariables";
import { convertSecondToDate } from "helpers/Date/DareConverter";
import { getStatusColor, truncateString } from "helpers/Utils";
import useLogBook from "hooks/logBook.hook";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import { addContactSuccess } from "store/Actions/config.actions";
import { setContestDetails } from "store/Actions/contest.actions";
import { TableColProps } from "types/Component";

function TableCol({ className, item }: TableColProps) {
  const { modalOpen, setModalOpen, setSelectedLogBook, dispatch } =
    useLogBook();
  const location = useLocation();
  const logbookDetail = () => {
    //disbale add contact success animation just in case it is enabled
    dispatch(addContactSuccess(false));
    // console.log("location.state?.spotData LOGBOOK", location.state?.spotData);
    setSelectedLogBook(item);
    // if (item.contestId) {
    dispatch(setContestDetails(null));
    // }
    globalNavigate(
      `/log-contacts/add-contact?tab=Log+Contacts&logbook-id=${item?.id}&logbook-name=${item?.name}`,
      {
        state: { item, spotData: location?.state?.spotData },
      }
    );
  };

  const renderLocationName = () => {
    if (item?.defaultLocation?.isCustom || !item?.defaultLocation?.name)
      return null;
    return (
      <div className="px-2 py-[1px] bg-[#0243DD] text-white text-xs ml-5 ">
        {truncateString(item?.defaultLocation?.name, 20)}
      </div>
    );
  };

  return (
    <tr className={`${className ? className : ""}`}>
      <td
        className="pl-4 text-[#17F9DA] text-xs font-[Barlow] font-normal py-3"
        onClick={logbookDetail}
      >
        <div className="flex">
          <span>{item?.name}</span>
          {renderLocationName()}
        </div>
      </td>
      <td
        className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal"
        onClick={logbookDetail}
      >
        {item?.timestamp && item.timestamp.seconds
          ? convertSecondToDate(item.timestamp.seconds)
          : null}
      </td>
      <td
        className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal"
        onClick={logbookDetail}
      >
        {item?.lastContactUpdatedAt && item?.lastContactUpdatedAt?.seconds
          ? convertSecondToDate(item?.lastContactUpdatedAt?.seconds)
          : "-"}
      </td>
      <td
        className="pl-4 py-3 text-white text-xs font-[Barlow] font-normal"
        onClick={logbookDetail}
      >
        {item?.contactCount ? item?.contactCount : item?.successContacts || 0}
      </td>
      <td
        className="text-xs text-[#17F9DA] font-[Barlow] font-bold text-center py-3 w-16"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={() => setModalOpen(true)}
        onMouseLeave={() => setModalOpen(false)}
      >
        {item.deleteInProgress ? (
          <div className="flex">
            <span className="text-red font-Play">Deleting...</span>
          </div>
        ) : (
          <>
            ...
            {modalOpen ? (
              <TableDropDown
                listItems={
                  item.adiFile
                    ? ADILogBookTableDropDowns
                    : LogBookTableDropDowns
                }
                itemData={item}
                onClick={() => {
                  setSelectedLogBook(item);
                }}
              />
            ) : null}
          </>
        )}
      </td>
    </tr>
  );
}

export default TableCol;
