import Icons from "assets/Icons";
import { VerticalDivider } from "components/Divider";
import TitledDefaultCountryPicker from "components/Inputs/TitledDefaultCountryPicker";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import TitledInput from "components/Inputs/TitledInput";
import {
  findCountryCode,
  getMyActivityIcon,
  getParkTypeName,
} from "helpers/Utils";
import { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { LocationMappings } from "constants/LocationMappings";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { Activities, ParkReferenceModel } from "types/Models";
import TitledInputDefault from "components/Inputs/TitledInputDefault";

interface ActivitiesLocationSettingsProps {
  setValues: any;
  values: any;
  selectedActivities: any;
}

export const LocationSource = {
  NONE: "NONE",
  PARK: "PARK",
  CUSTOM: "CUSTOM",
  MYLOCATION: "MYLOCATION",
};

export const ActivitiesLocationSettings = ({
  setValues,
  values,
  selectedActivities,
}: ActivitiesLocationSettingsProps) => {
  const { locationForm, findLocation } = useLogbookSetting();
  const [canEdit, setCanEdit] = useState(true);
  const [locationSource, setLocationSource] = useState(LocationSource.NONE);

  const setLocationFromPark = (park: ParkReferenceModel) => {
    setValues((prev: any) => ({
      ...prev,
      country: park?.entityName || "",
      state: park?.locationName || "",
      grid: park?.grid6 || "",
      latitude: park?.latitude || "",
      longitude: park?.longitude || "",
      locationSource: LocationSource.PARK,
      reference: park?.reference || "",
      type: park?.type || Activities.POTA,
    }));
    setLocationSource(LocationSource.PARK);
  };

  useEffect(() => {
    if (locationForm) {
      // setValues((prev: any) => ({
      //   ...prev,
      //   country: locationForm?.locationCountry || "",
      //   state: locationForm?.locationState || "",
      //   grid: locationForm?.locationGrid || "",
      //   latitude: locationForm?.locationLat || "",
      //   longitude: locationForm?.locationLong || "",
      //   locationSource: LocationSource.MYLOCATION,
      // }));
      // setLocationSource(LocationSource.MYLOCATION);
    }
  }, [locationForm]);

  const onEditClickHandler = () => {
    setCanEdit(false);
  };

  const onSaveClickHandler = () => {
    setCanEdit(true);
  };

  return (
    <div className="flex flex-col gap-2 text-white w-full mt-2">
      <div className="flex flex-col md:flex-row gap-2 md:gap-6 items-start md:items-end">
        <div className="font-bold text-md">Location Settings</div>
        {locationSource === LocationSource.PARK ? (
          <div className="text-sm text-cyan-400">
            Automatically pulled from{" "}
            {getParkTypeName(values?.type || Activities.POTA)}{" "}
            {values?.reference || ""}
          </div>
        ) : null}
      </div>
      {canEdit ? (
        <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px]">
          <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
            <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
              <div className="flex flex-row items-center gap-1">
                <div>
                  {values?.country ? (
                    <ReactCountryFlag
                      countryCode={
                        findCountryCode({
                          name: values?.country || "",
                        })?.alpha2
                      }
                      svg
                      style={{
                        width: "3em",
                        height: "1.2em",
                      }}
                      title={values?.country || ""}
                    />
                  ) : null}
                </div>
                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                  <div className="font-bold">Country:</div>
                  <div className=" max-w-[100px]">{values?.country}</div>
                </div>
              </div>
              <VerticalDivider />
              <div className="flex flex-row items-center gap-3">
                <div>
                  <img src={Icons.MapIcon} alt="" />
                </div>
                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                  <div className="font-bold">State:</div>
                  <div>{values?.state}</div>
                </div>
              </div>
              <VerticalDivider />
              <div className="flex flex-row items-center gap-3">
                <div>
                  <img src={Icons.GridsIcon} alt="" />
                </div>
                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                  <div className="font-bold">Grid:</div>
                  <div>{values?.grid}</div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <VerticalDivider />
              <img
                className="cursor-pointer"
                src={Icons.EditPenIcon}
                alt=""
                onClick={onEditClickHandler}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row text-white px-3 py-1 bg-[#0D1E5D] gap-6  overflow-x-auto whitespace-nowrap">
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={() => findLocation()}
            >
              <img src={Icons.MyLocation} alt="" />
              <div className="text-sm font-Play">Find My Location</div>
            </div>

            {selectedActivities?.map((park: any, index: number) => (
              <div
                className="flex flex-row gap-2 cursor-pointer"
                key={index}
                onClick={() => setLocationFromPark(park)}
              >
                <img
                  src={getMyActivityIcon(park.type || Activities.POTA)}
                  alt=""
                />
                <div className="text-sm font-Play">{park?.reference}</div>
              </div>
            ))}
          </div>
          <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px]">
            <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
              <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                <div className="flex flex-col lg:flex-row items-center gap-1">
                  <TitledDefaultCountryPicker
                    setText={(val) => {
                      setValues((prev: any) => ({
                        ...prev,
                        country: val,
                      }));
                      setLocationSource(LocationSource.CUSTOM);
                    }}
                    name="country"
                    title="Country"
                    placeHolder="Country"
                    value={
                      LocationMappings[`${values.country}`] || values?.country
                    }
                    className={"bg-white bg-opacity-10 h-[36px]"}
                    tabindex={8}
                    width="w-[80%] lg:w-[22%]"
                    error={undefined}
                    containerClass="mb-[20px]"
                  />
                  {values?.country ? (
                    <TitledDefaultRegionPicker
                      setText={(val) => {
                        setValues((prev: any) => ({
                          ...prev,
                          state: val,
                        }));
                        setLocationSource(LocationSource.CUSTOM);
                      }}
                      name="state"
                      title="State"
                      placeHolder="State"
                      tabindex={9}
                      width="w-[80%] lg:w-[22%]"
                      className={"bg-white bg-opacity-10 h-[36px]"}
                      value={values?.state}
                      error={undefined}
                      country={
                        LocationMappings[`${values.country}`] || values?.country
                      }
                      containerClass="mb-[20px]"
                    />
                  ) : null}
                  <TitledInputDefault
                    setText={(e) => {
                      const value = e.target.value;
                      setValues((prev: any) => ({
                        ...prev,
                        grid: value,
                      }));
                      setLocationSource(LocationSource.CUSTOM);
                    }}
                    name="grid"
                    title="Grid"
                    value={values?.grid}
                    placeHolder="Grid"
                    className="bg-white bg-opacity-10 h-[36px]"
                    width="w-[80%] lg:w-[15%]"
                    // error={
                    //   errors?.band && touched?.band ? errors?.band : undefined
                    // }
                    containerClass=""
                  />
                  <TitledInputDefault
                    setText={(e) => {
                      const value = e.target.value;
                      setValues((prev: any) => ({
                        ...prev,
                        latitude: value,
                      }));
                      setLocationSource(LocationSource.CUSTOM);
                    }}
                    name="latitude"
                    title="Latitude"
                    value={values?.latitude}
                    placeHolder="latitude"
                    className="bg-white bg-opacity-10 h-[36px]"
                    width="w-[80%] lg:w-[16%]"
                    // error={
                    //   errors?.band && touched?.band ? errors?.band : undefined
                    // }
                    containerClass=""
                  />
                  <TitledInputDefault
                    setText={(e) => {
                      const value = e.target.value;
                      setValues((prev: any) => ({
                        ...prev,
                        longitude: value,
                      }));
                      setLocationSource(LocationSource.CUSTOM);
                    }}
                    name="longitude"
                    title="Longitude"
                    value={values?.longitude}
                    placeHolder="longitude"
                    className="bg-white bg-opacity-10 h-[36px]"
                    width="w-[80%] lg:w-[16%]"
                    // error={
                    //   errors?.band && touched?.band ? errors?.band : undefined
                    // }
                    containerClass=""
                  />
                </div>
                <div className="flex items-center justify-center gap-4">
                  <VerticalDivider />
                  <img
                    className="cursor-pointer"
                    src={Icons.SaveIcon}
                    alt=""
                    onClick={onSaveClickHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
