import MultiSelectDropDown from "components/DropDown/DropDown/MultiSelectDropDown";
import { dxccCountryList } from "constants/DXCCCountryList";
import { useEffect, useMemo } from "react";
import { RegionDropdown } from "react-country-region-selector";

const StationLocationFilter: React.FC<{
  onApplyFilter: (field: string, value: any) => void;
  countries: any;
  setCountries: any;
  region: any;
  setRegion: any
}> = ({ onApplyFilter, countries, setCountries, region, setRegion }) => {

  const countryOptions = useMemo(
    () =>
      dxccCountryList.map((data, index) => {
        return { name: data.country, id: index + 1 };
      }),
    []
  );
   // Transform countries prop to ensure it matches the required format
   useEffect(() => {
    const transformedCountries = countries.map((country: any) => {
      if (typeof country === 'string') {
        const foundCountry = countryOptions.find((option) => option.name === country);
        return foundCountry || { name: country, id: -1 }; // Handle case where country is not found
      }
      return country;
    });
    setCountries(transformedCountries);
  }, [countries]);


  return (
    <div className="">
      <MultiSelectDropDown
        options={countryOptions}
        value={countries}
        onSelectValue={(selectedList) => {
          setCountries(selectedList);
          setRegion('');
        }}
        onRemoveValue={(selectedList) => {
          setCountries(selectedList);
        }}
        placeholder={"Country"}
        width="210px"
        optionContainer={{ height: "100px" }}
      />
      {countries && countries.length === 1 ? (
        <RegionDropdown
          country={countries[0].name}
          value={region}
          blankOptionLabel="Region"
          defaultOptionLabel="Region"
          onChange={(txt) => {
            setRegion(txt);
          }}
          classes="bg-[#23234C] text-white font-[Barlow] text-sm ml-3 mb-2 w-[100px] h-[30px]"
        />
      ) : null}

    </div>
  );
};

export default StationLocationFilter;
