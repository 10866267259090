import React, { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";

import { Activities, ActivitiesMapData } from "types/Models";
import { adjustedCoordinates, getActivityIcon } from "helpers/Utils";
import ActivityInfo from "./ActivityInfo";

// define the props interface
interface ActivityMarkerProps {
  activity: ActivitiesMapData;
  index: number;
  onCreateLogbook: (activity: ActivitiesMapData) => void;
  clusterer?: any;
  setInfoWindowOpen: (value: boolean) => void;
}

const ActivityMarker = React.memo(
  ({
    activity,
    index,
    onCreateLogbook,
    clusterer,
    setInfoWindowOpen,
  }: ActivityMarkerProps) => {
    const [infoVisibile, setInfoVisibile] = useState(false);

    return (
      <MarkerF
        key={`${activity.latitude}-${activity.longitude}-${index}`}
        position={adjustedCoordinates(
          activity.latitude,
          activity.longitude,
          index
        )}
        onClick={() => {
          setInfoVisibile(!infoVisibile);
          setInfoWindowOpen(true);
        }}
        clusterer={clusterer}
        icon={{
          url: getActivityIcon(activity.type || Activities.POTA),
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      >
        {infoVisibile && (
          <InfoWindowF
            onCloseClick={() => {
              setInfoVisibile(false);
              setInfoWindowOpen(false);
            }}
            position={{
              lat: activity.latitude || 0,
              lng: activity.longitude || 0,
            }}
          >
            <ActivityInfo
              activity={activity}
              index={index}
              setInfoVisibile={setInfoVisibile}
              onCreateLogbook={onCreateLogbook}
              setInfoWindowOpen={setInfoWindowOpen}
            />
          </InfoWindowF>
        )}
      </MarkerF>
    );
  },
  (prevProps, nextProps) =>
    prevProps.activity.latitude === nextProps.activity.latitude &&
    prevProps.activity.longitude === nextProps.activity.longitude
);

export default ActivityMarker;
