import { getRandomAvatar } from "helpers/Utils";
import useProfile from "hooks/profile.hook";
import React, { useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";

interface UserAvatarProps {
  className?: string;
  isCurrentUser?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  isCurrentUser = false
}) => {
  return (<BsPersonCircle className={className} color="#fff" />)
};
