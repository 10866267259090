import React from "react";
import Switch from "react-switch";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  height?: number;
  width?: number;
  handleDiameter?: number;
  onColor?: string;
  offColor?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  checked,
  onChange,
  height = 20,
  width = 40,
  handleDiameter = 18,
  onColor = "#0B1437",
}) => {
  return (
    <div
      className="w-[150px] h-[30px] flex items-center justify-between rounded-md px-3 cursor-pointer border border-[#5a5a7a] bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
      onClick={(e) => e.stopPropagation()}
    >
      <span className="text-white font-Play text-sm mr-2">{label}</span>
      <Switch
        onChange={onChange}
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={height}
        width={width}
        onColor={onColor}
        checkedHandleIcon={<div className="w-full h-full bg-[#7FFFD4] rounded-full" />}
        uncheckedHandleIcon={<div className="w-full h-full bg-[#7FFFD4] rounded-full" />}
      />
    </div>
  );
};

export default ToggleSwitch;
