import { LoadingState } from "types/State";
import { RootState } from "store/Reducers";
import { LogBookActionPayload } from "store/Actions/logBook.actions";
import {
  LOADING_AUTH_STATUS,
  LOADING_CALLSIGN_AVAILABILITY_STATUS,
  LOADING_FACEBOOK_AUTH_STATUS,
  LOADING_GOOGLE_AUTH_STATUS,
  LOADING_GUEST_USER_PROFILE_STATUS,
  LOADING_LEADERBOARD_STATUS,
  LOADING_LEADERBOARD_STATUS_BY_MODE,
  LOADING_LOGBOOK_SAVEING_STATUS,
  LOADING_LOGBOOK_STATUS,
  LOADING_PROFILE_STATUS,
  SUBSCRIPTION_LOADING,
  FETCH_AWARD_LOADING,
  LOADING_STATUS_FOR_GETTING_LOCATION,
  LOADING_CALLSIGN_DATA,
  LOADING_UPLOAD_STATUS,
  FEATURED_MEMBERS_LOADING,
  LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS,
  ACTIVE_CONTEST_LOADING,
  UPCOMING_CONTEST_LOADING,
  PASSED_CONTEST_LOADING,
  ENROLL_CONTEST_LOADING,
  CONTESTANT_DETAIL_LOADING,
  CONTEST_LEADER_LOADING,
  CONTEST_LEADER_BOARD_LOADING,
  CONTEST_CONTACTS_LOADING,
  CONTEST_DETAIL_LOADING,
  CONTESTANT_PROFILE_DETAIL_LOADING,
  MAP_MEMBERS_LOADING,
  GLOBE_MEMBERS_LOADING,
  LEADER_BOARD_BY_WEEK,
  LEADER_BOARD_MODE_LOADING,
  LOADING_DISTANCE_LEADERBOARD,
  LOADING_DX_LEADERBOARD,
  LOADING_STATE_LEADER_BOARD,
  ADI_UPLOAD_LOADING,
  ADMIN_CONTROL_LOADING,
  DAILY_LEADER_LOADING,
  SPOT_LOADING,
  LOGBOOK_SETTING_LOADING,
  LOADING_APPLE_AUTH,
  ACTIVITIES_MAP_LOADING,
} from "store/Actions/loading.action";
import { RESET_LOADING_STATUS } from "constants/ActionTypes";
import { LOADING_MEMBERS_DATA_STATUS } from "store/Actions/members.actions";
import { CONTEST_LOADING_STATUS } from "store/Actions/contest.actions";
const defaultState: LoadingState = {
  authIsLoading: false,
  isFacebookLoading: false,
  isGoogleLoading: true,
  discourseLoading: false,
  logIsLoading: false,
  contactIsSavingLoading: false,
  isProfileLoading: false,
  isLeaderBoardLoading: false,
  isLeaderBoardByModeLoading: false,
  isSubscriptionLoading: false,
  isFetchingGuestUserProfile: true,
  isUserAwardLoading: false,
  isFetchingCallSignAvailability: false,
  isGettingLocation: false,
  isCallSignDataLoading: false,
  isUploadImageLoading: false,
  isMembersDataLoading: false,
  isFeaturedMembersLoading: false,
  isLogContactForAdifLoading: false,
  isContestLoading: false,
  isActiveContestLoading: false,
  isUpcomingContestLoading: false,
  isPassedContestLoading: false,
  isEnrollContestLoading: false,
  isContestantDetailLoading: false,
  isContestLeaderLoading: false,
  isContestLeaderBoardLoading: false,
  isContestContactsLoading: false,
  isContestDetailLoading: false,
  isContestUserDetailLoading: false,
  isMapMembersLoading: false,
  isGlobeMembersLoading: false,
  isLeaderBoardByWeekLoading: false,
  isLeaderBoardWeekByModeLoading: false,
  isDistanceLeaderBoardLoading: false,
  isDXLoading: false,
  isStateLeaderBoardLoading: false,
  isAdiUploadLoading: false,
  isAdminControlLoading: false,
  isDailyLeadersLoading: false,
  isSpottingLoading: false,
  isLogbookSettingLoading: false,
  isAppleAuthLoading: false,
  isActivitiesMapLoading: false,
};

export default function loadingReducer(
  state = defaultState,
  action: LogBookActionPayload
) {
  switch (action.type) {
    case LOADING_CALLSIGN_DATA:
      return {
        ...state,
        isCallSignDataLoading: action.payload,
      };
    case LOADING_AUTH_STATUS:
      return {
        ...state,
        authIsLoading: action.payload,
      };
    case LOADING_FACEBOOK_AUTH_STATUS:
      return {
        ...state,
        isFacebookLoading: action.payload,
      };
    case FETCH_AWARD_LOADING:
      return {
        ...state,
        isUserAwardLoading: action.payload,
      };
    case LOADING_GOOGLE_AUTH_STATUS:
      return {
        ...state,
        isGoogleLoading: action.payload,
      };
    // case DISCOURSE_LOADING:
    //   return {
    //     ...state,
    //     discourseLoading: action.payload,
    //   };
    case LOADING_LOGBOOK_STATUS:
      return {
        ...state,
        logIsLoading: action.payload,
      };
    case LOADING_LOGBOOK_CONTACTS_FOR_ADIF_EXPORT_STATUS:
      return {
        ...state,
        isLogContactForAdifLoading: action.payload,
      };
    case LOADING_LOGBOOK_SAVEING_STATUS:
      return {
        ...state,
        contactIsSavingLoading: action.payload,
      };
    case LOADING_PROFILE_STATUS:
      return {
        ...state,
        isProfileLoading: action.payload,
      };
    case LOADING_LEADERBOARD_STATUS:
      return {
        ...state,
        isLeaderBoardLoading: action.payload,
      };
    case LOADING_LEADERBOARD_STATUS_BY_MODE:
      return {
        ...state,
        isLeaderBoardByModeLoading: action.payload,
      };
    case SUBSCRIPTION_LOADING:
      return {
        ...state,
        isSubscriptionLoading: action.payload,
      };
    case LOADING_GUEST_USER_PROFILE_STATUS:
      return {
        ...state,
        isFetchingGuestUserProfile: action.payload,
      };
    case LOADING_CALLSIGN_AVAILABILITY_STATUS:
      return {
        ...state,
        isFetchingCallSignAvailability: action.payload,
      };
    case LOADING_STATUS_FOR_GETTING_LOCATION:
      return {
        ...state,
        isGettingLocation: action.payload,
      };
    case LOADING_UPLOAD_STATUS:
      return {
        ...state,
        isUploadImageLoading: action.payload,
      };
    case LOADING_MEMBERS_DATA_STATUS:
      return {
        ...state,
        isMembersDataLoading: action.payload,
      };
    case FEATURED_MEMBERS_LOADING:
      return {
        ...state,
        isFeaturedMembersLoading: action.payload,
      };
    case CONTEST_LOADING_STATUS:
      return {
        ...state,
        isContestLoading: action.payload,
      };

    case ACTIVE_CONTEST_LOADING:
      return {
        ...state,
        isActiveContestLoading: action.payload,
      };

    case UPCOMING_CONTEST_LOADING:
      return {
        ...state,
        isUpcomingContestLoading: action.payload,
      };

    case PASSED_CONTEST_LOADING:
      return {
        ...state,
        isPassedContestLoading: action.payload,
      };
    case ENROLL_CONTEST_LOADING:
      return {
        ...state,
        isEnrollContestLoading: action.payload,
      };
    case CONTESTANT_DETAIL_LOADING:
      return {
        ...state,
        isContestantDetailLoading: action.payload,
      };
    case CONTEST_LEADER_LOADING:
      return {
        ...state,
        isContestLeaderLoading: action.payload,
      };
    case CONTEST_LEADER_BOARD_LOADING:
      return {
        ...state,
        isContestLeaderBoardLoading: action.payload,
      };
    case CONTEST_CONTACTS_LOADING:
      return {
        ...state,
        isContestContactsLoading: action.payload,
      };
    case CONTEST_DETAIL_LOADING:
      return {
        ...state,
        isContestDetailLoading: action.payload,
      };
    case CONTESTANT_PROFILE_DETAIL_LOADING:
      return {
        ...state,
        isContestUserDetailLoading: action.payload,
      };

    case MAP_MEMBERS_LOADING:
      return {
        ...state,
        isMapMembersLoading: action.payload,
      };
    case GLOBE_MEMBERS_LOADING:
      return {
        ...state,
        isGlobeMembersLoading: action.payload,
      };
    case LEADER_BOARD_BY_WEEK:
      return {
        ...state,
        isLeaderBoardByWeekLoading: action.payload,
      };
    case LEADER_BOARD_MODE_LOADING:
      return {
        ...state,
        isLeaderBoardWeekByModeLoading: action.payload,
      };
    case LOADING_DISTANCE_LEADERBOARD:
      return {
        ...state,
        isDistanceLeaderBoardLoading: action.payload,
      };
    case LOADING_DX_LEADERBOARD:
      return {
        ...state,
        isDXLoading: action.payload,
      };
    case LOADING_STATE_LEADER_BOARD:
      return {
        ...state,
        isStateLeaderBoardLoading: action.payload,
      };
    case ADI_UPLOAD_LOADING:
      return {
        ...state,
        isAdiUploadLoading: action.payload,
      };
    case ADMIN_CONTROL_LOADING:
      return {
        ...state,
        isAdminControlLoading: action.payload,
      };
    case DAILY_LEADER_LOADING:
      return {
        ...state,
        isDailyLeadersLoading: action.payload,
      };
    case SPOT_LOADING:
      return {
        ...state,
        isSpottingLoading: action.payload,
      };
    case LOGBOOK_SETTING_LOADING:
      return {
        ...state,
        isLogbookSettingLoading: action.payload,
      };
    case LOADING_APPLE_AUTH:
      return {
        ...state,
        isAppleAuthLoading: action.payload,
      };
    case ACTIVITIES_MAP_LOADING:
      return {
        ...state,
        isActivitiesMapLoading: action.payload,
      };
    case RESET_LOADING_STATUS:
      return {
        ...state,
        authIsLoading: false,
        isFacebookLoading: false,
        isGoogleLoading: false,
        discourseLoading: false,
        logIsLoading: false,
        contactIsSavingLoading: false,
        isProfileLoading: false,
        isLeaderBoardLoading: false,
        isLeaderBoardByModeLoading: false,
        isSubscriptionLoading: false,
        isUserAwardLoading: false,
        isFeaturedMembersLoading: false,
        isLogContactForAdifLoading: false,
        isContestLoading: false,
        isActiveContestLoading: false,
        isUpcomingContestLoading: false,
        isPassedContestLoading: false,
        isEnrollContestLoading: false,
        isContestantDetailLoading: false,
        isContestLeaderLoading: false,
        isContestLeaderBoardLoading: false,
        isContestContactsLoading: false,
        isContestDetailLoading: false,
        isContestUserDetailLoading: false,
        isMapMembersLoading: false,
        isGlobeMembersLoading: false,
        isLeaderBoardByWeekLoading: false,
        isLeaderBoardWeekByModeLoading: false,
        isDistanceLeaderBoardLoading: false,
        isDXLoading: false,
        isStateLeaderBoardLoading: false,
        isAdiUploadLoading: false,
        isAdminControlLoading: false,
        isDailyLeadersLoading: false,
        isSpottingLoading: false,
        isLogbookSettingLoading: false,
      };
    default:
      return state;
  }
}

// GET STATE
export const getIsAuthLoadingSelector = (state: RootState) => {
  return state.loading.authIsLoading;
};

export const getIsFacebookAuthLoadingSelector = (state: RootState) => {
  return state.loading.isFacebookLoading;
};

export const getIsGoogleAuthLoadingSelector = (state: RootState) => {
  return state.loading.isGoogleLoading;
};

// export const getDiscourseLoadingSelector = (state: RootState) => {
//   return state.loading.discourseLoading;
// };

export const getIsLogBookLoadingSelector = (state: RootState) => {
  return state.loading.logIsLoading;
};

export const getIsLogBookSavingLoadingSelector = (state: RootState) => {
  return state.loading.contactIsSavingLoading;
};

export const getIsProfileLoadingSelector = (state: RootState) => {
  return state.loading.isProfileLoading;
};

export const getIsLeaderBoardOverAllLoadingSelector = (state: RootState) => {
  return state.loading.isLeaderBoardLoading;
};

export const getIsLeaderBoardByModeLoadingSelector = (state: RootState) => {
  return state.loading.isLeaderBoardByModeLoading;
};
export const getIsSubscriptionLoadingSelector = (state: RootState) => {
  return state.loading.isSubscriptionLoading;
};
export const getIsFetchingGuestUserProfileSelector = (state: RootState) => {
  return state.loading.isFetchingGuestUserProfile;
};
export const getIsUserAwardLoadingSelector = (state: RootState) => {
  return state.loading.isUserAwardLoading;
};
export const getIsFetchingCallSignAvailabilitySelector = (state: RootState) => {
  return state.loading.isFetchingCallSignAvailability;
};

export const getisGettingLocationSelector = (state: RootState) => {
  return state.loading.isGettingLocation;
};

export const getIsCallSignDataLoadingSelector = (state: RootState) => {
  return state.loading.isCallSignDataLoading;
};

export const getIsUploadImageLoadingSelector = (state: RootState) => {
  return state.loading.isUploadImageLoading;
};

export const getIsMembersDataLoadingSelector = (state: RootState) => {
  return state.loading.isMembersDataLoading;
};

export const getIsFeaturedMemberLoadingSelector = (state: RootState) => {
  return state.loading.isFeaturedMembersLoading;
};

export const getIsLogContactForAdifLoadingSelector = (state: RootState) => {
  return state.loading.isLogContactForAdifLoading;
};

export const getIsContestLoadingSelector = (state: RootState) => {
  return state.loading.isContestLoading;
};

export const getIsActiveContestLoading = (state: RootState) => {
  return state.loading.isActiveContestLoading;
};

export const getIsUpcomingContestLoading = (state: RootState) => {
  return state.loading.isUpcomingContestLoading;
};

export const getIsPassedContestLoading = (state: RootState) => {
  return state.loading.isPassedContestLoading;
};

export const getIsEnrollContestLoading = (state: RootState) => {
  return state.loading.isEnrollContestLoading;
};

export const getIsContestantDetailLoading = (state: RootState) => {
  return state.loading.isContestantDetailLoading;
};

export const getIsContestLeaderLoading = (state: RootState) => {
  return state.loading.isContestLeaderLoading;
};

export const getIsContestLeaderBoardLoading = (state: RootState) => {
  return state.loading.isContestLeaderBoardLoading;
};

export const getIsContestContactsLoading = (state: RootState) => {
  return state.loading.isContestContactsLoading;
};

export const getIsContestDetailLoading = (state: RootState) => {
  return state.loading.isContestDetailLoading;
};

export const getIsContestUserDetailLoading = (state: RootState) => {
  return state.loading.isContestUserDetailLoading;
};
export const getIsMapMembersLoadingSelector = (state: RootState) => {
  return state.loading.isMembersDataLoading;
};

export const getIsGlobeMembersLoadingSelector = (state: RootState) => {
  return state.loading.isGlobeMembersLoading;
};

export const getIsLeaderBoardByWeekLoadingSelector = (state: RootState) => {
  return state.loading.isLeaderBoardByWeekLoading;
};

export const getIsLeaderBoardByModeWeekLoadingSelector = (state: RootState) => {
  return state.loading.isLeaderBoardWeekByModeLoading;
};

export const getIsDistanceLeaderBoardLoadingSelector = (state: RootState) => {
  return state.loading.isDistanceLeaderBoardLoading;
};

export const getIsDXLeaderBoardLoadingSelector = (state: RootState) => {
  return state.loading.isDXLoading;
};

export const getIsStateLeaderBoardLoadingSelector = (state: RootState) => {
  return state.loading.isStateLeaderBoardLoading;
};
export const getIsAdiUploadLoadingSelector = (state: RootState) => {
  return state.loading.isAdiUploadLoading;
};

export const getIsAdminControlLoadingSelector = (state: RootState) => {
  return state.loading.isAdminControlLoading;
};
export const getIsDailyLeadersLoadingSelector = (state: RootState) => {
  return state.loading.isDailyLeadersLoading;
};
export const getIsSpottingLoadingSelector = (state: RootState) => {
  return state.loading.isSpottingLoading;
};

export const getIsLogbookSettingLoadingSelector = (state: RootState) => {
  return state.loading.isLogbookSettingLoading;
};

export const getIsAppleAuthLoadingSelector = (state: RootState) => {
  return state.loading.isAppleAuthLoading;
};

export const getIsActivitiesMapLoadingSelector = (state: RootState) => {
  return state.loading.isActivitiesMapLoading;
};
