// store/index.ts
import configureStoreDev from "./configureStore.dev";
import configureStoreProd from "./configureStore.prod";
import { SagaStore, StoreConfig } from "types/State";

const config = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ? configureStoreDev
  : configureStoreProd;

const { store, persistor }: StoreConfig = config();

export { store, persistor }; // Export single instance
