import React, { useMemo, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, PolylineF } from "@react-google-maps/api";
import { mapStylesDark } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { FlatGoogleMapProps } from "types/Component";
import { MarkerLines, MarkerPoint } from "types/State";
import useMap from "hooks/map.hook";

import ContactMarker from "./ContactMarker";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import BorderButtonSquare from "components/Button/BorderButtonSquare";
import useNotification from "hooks/notification.hook";
import { useSelector } from "react-redux";
import { getContactMapModalStatus } from "store/Reducers/notification.reducer";
import { getIsLogBookLoadingSelector } from "store/Reducers/loading.reducer";
import { generateCurvePath } from "helpers/Utils";

const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const CurvedLine = ({ start, end, lineData, setHoverArc }: any) => {
  const onLoad = (polyline: any) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#17F9DA",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#17F9DA",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    geodesic: true,
  };

  const curvePath = [start, end];

  return (
    <PolylineF
      onLoad={onLoad}
      path={curvePath}
      options={options}
      onMouseOver={() => {
        if (setHoverArc) {
          setHoverArc(lineData);
        }
      }}
      onMouseOut={() => {
        if (setHoverArc) {
          setHoverArc(null);
        }
      }}
    />
  );
};

const FlatGoogleMap = ({
  className,
  allContactMode,
  setHoverArc,
  captureSnapshot,
  isLoadingImg,
  screenShotImage,
  limit,
  contest,
  userContactMap,
  mapControls,
  contactDetail,
  captureInProgress,
  showShare,
  restrictBounds,
  shouldFitBounds,
}: FlatGoogleMapProps) => {
  const [mapMarkers, setMapMarkers] = useState<MarkerPoint[]>([]);
  const [polylines, setPolylines] = useState<MarkerLines[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // const contactLoading = useSelector(getIsLogBookLoadingSelector);
  const { handleContactMapModalStatus } = useNotification();
  const contactMapModalStatus = useSelector(getContactMapModalStatus);

  const mapRef = useRef<any>(null);

  const {
    logBookMarkers,
    logBookMarkersLines,
    allContactMarkers,
    allContactMarkersLines,
    allContestContactMarkers,
    allContestContactMarkersLines,
    userContestMarkers,
    userContestMarkersLines,
    singleContactMarkerLines,
    singleContactMarkers,
  } = useMap();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
    libraries: googleLibraries,
  });
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const toggleMapControls = (enable: boolean) => {
    if (mapRef.current) {
      mapRef.current.setOptions({
        zoomControl: enable,
        fullscreenControl: enable,
      });
    }
  };

  useEffect(() => {
    toggleMapControls(
      mapControls?.fullscreenControl || mapControls?.zoomControl || false
    );
  }, [mapControls?.fullscreenControl, mapControls?.zoomControl]);

  useEffect(() => {
    if (!allContactMode && !contest) {
      if (limit) {
        const newMarkers = [...logBookMarkers.slice(0, limit)];
        setMapMarkers(newMarkers);
      } else {
        const newMarkers = [...logBookMarkers];
        setMapMarkers(newMarkers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBookMarkers]);

  useEffect(() => {
    if (allContactMode) {
      if (limit) {
        const newMarkers = [...allContactMarkers.slice(0, limit)];
        setMapMarkers(newMarkers);
      } else {
        const newMarkers = [...allContactMarkers];
        setMapMarkers(newMarkers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContactMarkers]);
  useEffect(() => {
    if (!allContactMode && !contest) {
      if (limit) {
        const newLines = [...logBookMarkersLines.slice(0, limit)];
        setPolylines(newLines);
      } else {
        const newLines = [...logBookMarkersLines];
        setPolylines(newLines);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logBookMarkersLines]);
  useEffect(() => {
    if (allContactMode) {
      if (limit) {
        const newLines = [...allContactMarkersLines.slice(0, limit)];
        setPolylines(newLines);
      } else {
        const newLines = [...allContactMarkersLines];
        setPolylines(newLines);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContactMarkersLines]);

  useEffect(() => {
    if (contest) {
      if (limit) {
        const newLines = [...allContestContactMarkersLines.slice(0, limit)];
        setPolylines(newLines);
      } else {
        const newLines = [...allContestContactMarkersLines];
        setPolylines(newLines);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContestContactMarkersLines]);
  useEffect(() => {
    if (contest) {
      if (limit) {
        const newMarkers = [...allContestContactMarkers.slice(0, limit)];
        setMapMarkers(newMarkers);
      } else {
        const newMarkers = [...allContestContactMarkers];
        setMapMarkers(newMarkers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContestContactMarkers]);

  useEffect(() => {
    if (userContactMap) {
      if (limit) {
        const newLines = [...userContestMarkersLines.slice(0, limit)];
        setPolylines(newLines);
      } else {
        const newLines = [...userContestMarkersLines];
        setPolylines(newLines);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContestMarkersLines]);

  useEffect(() => {
    if (userContactMap) {
      if (limit) {
        const newMarkers = [...userContestMarkers.slice(0, limit)];
        setMapMarkers(newMarkers);
      } else {
        const newMarkers = [...userContestMarkers];
        setMapMarkers(newMarkers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContestMarkers]);

  useEffect(() => {
    if (isLoaded && allContactMode) {
      let newMarkers: MarkerPoint[] = [];
      let newLines: MarkerLines[] = [];
      if (limit) {
        newMarkers = [...allContactMarkers.slice(0, limit)];
        newLines = [...allContactMarkersLines.slice(0, limit)];
      } else {
        newMarkers = [...allContactMarkers];
        newLines = [...allContactMarkersLines];
      }
      setMapMarkers(newMarkers);
      setPolylines(newLines);
      setIsLoading(false);
    } else if (isLoaded && contest) {
      let newMarkers: MarkerPoint[] = [];
      let newLines: MarkerLines[] = [];
      if (limit) {
        newMarkers = [...allContestContactMarkers.slice(0, limit)];
        newLines = [...allContestContactMarkersLines.slice(0, limit)];
      } else {
        newMarkers = [...allContestContactMarkers];
        newLines = [...allContestContactMarkersLines];
      }
      setMapMarkers(newMarkers);
      setPolylines(newLines);
      setIsLoading(false);
    } else if (isLoaded && userContactMap) {
      let newMarkers: MarkerPoint[] = [];
      let newLines: MarkerLines[] = [];
      if (limit) {
        newMarkers = [...userContestMarkers.slice(0, limit)];
        newLines = [...userContestMarkersLines.slice(0, limit)];
      } else {
        newMarkers = [...userContestMarkers];
        newLines = [...userContestMarkersLines];
      }
      setMapMarkers(newMarkers);
      setPolylines(newLines);
      setIsLoading(false);
    } else if (isLoaded && !contactDetail) {
      let newMarkers: MarkerPoint[] = [];
      let newLines: MarkerLines[] = [];
      if (limit) {
        newMarkers = [...logBookMarkers.slice(0, limit)];
        newLines = [...logBookMarkersLines.slice(0, limit)];
      } else {
        newMarkers = [...logBookMarkers];
        newLines = [...logBookMarkersLines];
      }
      setMapMarkers(newMarkers);
      setPolylines(newLines);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (contactDetail) {
      const newMarkers = [...singleContactMarkers];
      setMapMarkers(newMarkers);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleContactMarkers]);

  useEffect(() => {
    if (contactDetail) {
      const newLines = [...singleContactMarkerLines];
      setPolylines(newLines);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleContactMarkerLines]);
  const onMapLoad = (map: any) => {
    mapRef.current = map;
  };

  useEffect(() => {
    if (contactDetail) {
      if (mapRef.current && mapMarkers.length) {
        const bounds = new window.google.maps.LatLngBounds();
        mapMarkers.forEach((marker) => {
          const position = new google.maps.LatLng(marker.lat, marker.lng);
          bounds.extend(position);
        });
        setTimeout(() => {
          mapRef.current.fitBounds(bounds);
        }, 500);
        if (mapMarkers.length === 1) {
          const listener = google.maps.event.addListener(
            mapRef.current,
            "idle",
            function () {
              if (mapRef.current.getZoom() > 4) {
                mapRef.current.setZoom(4);
              }
              google.maps.event.removeListener(listener);
            }
          );
        }
      } else {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMarkers]);
  useEffect(() => {
    if (mapRef.current && mapMarkers.length > 0 && shouldFitBounds) {
      const bounds = new window.google.maps.LatLngBounds();

      mapMarkers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      // Fit the map to the bounds
      mapRef.current.fitBounds(bounds);
    }
  }, [mapMarkers, mapRef.current, shouldFitBounds]);
  return (
    <div className={className}>
      {!isLoaded || isLoading ? (
        <RouteLoading />
      ) : (
        <GoogleMap
          mapContainerClassName="map-container no-close-button-on-info-windows"
          center={center}
          zoom={2}
          onLoad={onMapLoad}
          options={{
            styles: mapStylesDark,
            streetViewControl: mapControls?.streetViewControl || false,
            rotateControl: mapControls?.rotateControl || false,
            mapTypeControl: mapControls?.mapTypeControl || false,
            fullscreenControl: mapControls?.fullscreenControl || false,
            zoomControl: mapControls?.zoomControl || false,
            scrollwheel: mapControls?.scrollwheel ?? true, // Default is true
            ...(restrictBounds
              ? {
                  restriction: {
                    latLngBounds: {
                      north: 85,
                      south: -85,
                      west: -179.9999,
                      east: 179.9999,
                    },
                    strictBounds: true,
                  },
                }
              : {}),
          }}
        >
          {mapMarkers
            ? mapMarkers.map((position, index) => {
                return (
                  <>
                    <ContactMarker position={position} index={index} />
                  </>
                );
              })
            : null}
          {polylines.map((line: MarkerLines, index) => {
            return (
              <CurvedLine
                key={`${line.start}-${line.end}-${index}`}
                start={line.start}
                end={line.end}
                lineData={line}
                setHoverArc={setHoverArc}
              />
            );
          })}
        </GoogleMap>
      )}

      {!allContactMode &&
      !screenShotImage &&
      !captureInProgress &&
      showShare ? (
        <div className="absolute bottom-0 left-0 px-7 py-2 bg-[#4D519A]">
          <p className="text-white font-[Barlow] font-bold text-lg">
            Share your QSO map :
          </p>
          <div
            className="flex items-center justify-center mt-1"
            onClick={() => {
              if (!contactMapModalStatus) {
                handleContactMapModalStatus(true);
              } else if (captureSnapshot) {
                captureSnapshot();
              }
            }}
          >
            <div className="w-[24]">
              <BorderButtonSquare
                text={"Share"}
                onClick={() => {}}
                className=""
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FlatGoogleMap;
