import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import BorderButtonSquare from "components/Button/BorderButtonSquare";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import TextInput from "components/Inputs/TextInput";
import TitledInput from "components/Inputs/TitledInput";
import { LogbookTemplatesOptions, Templates } from "constants/Config";
import useLogBook from "hooks/logBook.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { PotaActivation } from "../PotaActivation";
import { Activities, SavedLocation } from "types/Models";
import { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import {
  ActivitiesLocationSettings,
  LocationSource,
} from "components/ActivitiesLocationSettings";
import { SliderButtonGroup } from "components/SelectableButtonsGroup/SliderButtonGroup";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup/SelectableButtonsGroup";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { parse } from "path";
import { createNewLogBookAction } from "store/Actions/logBook.actions";

export const LocationTabs = {
  SAVED: "saved",
  CUSTOM: "custom",
};
function LogBookWrapper() {
  const {
    newLogBookName,
    setNewLogBookName,
    newLogBookTemplate,
    createNewLogBook,
    isSavingOnLoading,
    setSelectedLogBook,
  } = useLogBook();
  const dispatch = useDispatch();
  const [selectedActivity, setSelectedActivity] = useState<string[]>([]);
  const userProfile = useSelector(getUserProfileSelector);
  const [formData, setFormData] = useState<any>({});
  const [locationData, setLocationData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<string>(LocationTabs.SAVED);
  const [template, setTemplate] = useState<string>(Templates.DEFAULT_WRL);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 450);
  const { savedLocationOptions, savedLocations, fetchUserSavedLocation } =
    useLogbookSetting();

  useEffect(() => {
    setSelectedLogBook(null);
    fetchUserSavedLocation();

    if (userProfile?.savedLocationId) {
      setLocationData(
        savedLocations.find(
          (location) => location.id === userProfile?.savedLocationId
        )
      );
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (formData?.activitiesData && formData?.activitiesData[0]) {
      const activity = formData?.activitiesData[0];
      setSelectedTab(LocationTabs.CUSTOM);
      setLocationData({
        country: activity.entityName || "",
        state: activity.locationName || "",
        grid: activity.grid6 || "",
        latitude: activity.latitude || "",
        longitude: activity.longitude || "",
        locationSource: LocationSource.PARK,
        reference: activity.reference || "",
      });
    }
  }, [formData]);

  const formikRef = useRef<any>();
  return (
    <div className="glassmorphism w-5/6 sm:w-2/3 md:w-1/2 py-11 px-5 sm:px-7 md:px-9 lg:p-12 flex flex-col items-center dashboardWrapper">
      <div className="w-full text-white">
        <h4 className="font-['Barlow'] text-base text-white mb-2.5 font-bold">
          Logbook Name
        </h4>
        <TextInput
          text={newLogBookName}
          setText={(text) => {
            setNewLogBookName(text);
          }}
          placeHolder="Logbook Name *"
          name="logbookName"
          className="mb-2.5"
        />
        <p className="font-[Barlow] font-normal text-[#17F9DA] text-xs">
          Choose any name! Example: HF Log, 10M Log, SSB, POTA
        </p>

        <hr className="w-full border-[#fff] opacity-10 border-0.5 my-6" />
        <div className="flex flex-col text-white w-full mt-6">
          <div className="flex w-full flex-col">
            <LogbookSettingDropdown
              list={LogbookTemplatesOptions}
              title="Default Template For New Logbook:"
              value={template}
              setValue={(value) => {
                if (value === "view") {
                  globalNavigate("/log-contacts/logbook-styles");
                } else {
                  setTemplate(value);
                }
              }}
              className="sm:w-[99%] md:w-[99%]"
            />
            <p className="font-[Barlow] font-normal text-[#17F9DA] text-xs">
              Choose the layout for the logging interface
            </p>
          </div>
        </div>
        <hr className="w-full border-[#fff] opacity-10 border-0.5 my-6" />
        <p className="font-[Barlow] font-bold">Add Activities</p>
        <div className="flex mt-3">
          {!selectedActivity.includes(Activities.POTA) && (
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                setSelectedActivity([...selectedActivity, Activities.POTA]);
              }}
            >
              <img src={Icons.PotaIconAdd} alt="" width={90} />
            </div>
          )}

          {!selectedActivity.includes(Activities.WWFF) && (
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                setSelectedActivity([...selectedActivity, Activities.WWFF]);
              }}
            >
              <img src={Icons.WwffIcon} alt="" width={90} />
            </div>
          )}

          {!selectedActivity.includes(Activities.IOTA) && (
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                setSelectedActivity([...selectedActivity, Activities.IOTA]);
              }}
            >
              <img src={Icons.IotaIcon} alt="" width={90} />
            </div>
          )}

          {!selectedActivity.includes(Activities.SOTA) && (
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                setSelectedActivity([...selectedActivity, Activities.SOTA]);
              }}
            >
              <img src={Icons.SotaIcon} alt="" width={90} />
            </div>
          )}
        </div>
        <div className="mt-6">
          {selectedActivity.map((activity) => (
            <PotaActivation
              multiActivities
              locationData={locationData}
              activityType={activity}
              onActivityClose={() => {
                setSelectedActivity(
                  selectedActivity.filter((act) => act !== activity)
                );
                //also remove from form data
                setFormData({
                  ...formData,
                  activitiesData: formData?.activitiesData?.filter(
                    (act: any) => act.type !== activity
                  ),
                  activities: formData?.activitiesData?.filter(
                    (act: any) => act.type !== activity
                  ),
                });
              }}
              formikRef={formikRef}
              formData={formData}
              setFormData={setFormData}
            />
          ))}
        </div>
        <div className="w-full mt-3">
          {isMobile ? (
            <SelectableButtonsGroup
              initialSelected={selectedTab}
              disabled={false}
              actions={[
                {
                  id: "saved",
                  label: "Saved Location",
                  tabIndex: 8,
                },
                {
                  id: "custom",
                  label: "Custom Location",
                  tabIndex: 8,
                },
              ]}
              onButtonChange={(action) => {
                if (action && action.id) {
                  setSelectedTab(action.id as string);
                }
              }}
            />
          ) : (
            <SliderButtonGroup
              initialSelected={selectedTab}
              disabled={false}
              sliderClass="w-[48.5%]"
              buttonClass="w-1/2"
              actions={[
                {
                  id: "saved",
                  label: "Saved Location",
                  tabIndex: 8,
                },
                {
                  id: "custom",
                  label: "Custom Location",
                  tabIndex: 8,
                },
              ]}
              onButtonChange={(action) => {
                if (action && action.id) {
                  setSelectedTab(action.id as string);
                }
              }}
            />
          )}
        </div>
        {selectedTab === LocationTabs.SAVED ? (
          <LogbookSettingDropdown
            list={savedLocationOptions}
            title="Saved Locations:"
            value={userProfile?.savedLocationId || locationData?.id || ""}
            setValue={(value) => {
              if (value === "add") {
                globalNavigate("/log-contacts/saved-locations");
              } else {
                const location =
                  savedLocations.find(
                    (location: SavedLocation) => location.id === value
                  ) || null;
                if (location) {

                  setLocationData({
                    ...location,
                    grid: location.gridsquare,
                    latitude: location.lat,
                    longitude: location.lng,
                  });
                }
              }
            }}
            className={`w-[99.9%] sm:w-[99.9%] md:w-[99.9%] mt-3`}
          />
        ) : (
          <ActivitiesLocationSettings
            setValues={setLocationData}
            values={locationData}
            selectedActivities={formData?.activitiesData}
          />
        )}
      </div>
      <BorderButtonSecondary
        isLoading={isSavingOnLoading}
        text={"Save"}
        onClick={() => {
          //if formik ref is available it means user has added activities if not we create the logbook without activities
          //TODO: handle everything in formik context
          if (formikRef.current) {
            formikRef.current.submitForm();
          } else {
            if (newLogBookName) {
              let newLogbookData: any = {
                name: newLogBookName,
                logbookStyle: template,
                defaultCallSign: userProfile?.callSign || "",
              };
              let defaultLocation = null;
              if (selectedTab === LocationTabs.SAVED) {
                defaultLocation = locationData;
              } else if (selectedTab === LocationTabs.CUSTOM) {
                defaultLocation = {
                  isCustom: true,
                  country: locationData?.country || "",
                  state: locationData?.state || "",
                  qth: "",
                  lat: parseFloat(locationData?.latitude),
                  lng: parseFloat(locationData?.longitude),
                  gridsquare: locationData?.grid,
                  id: "",
                  locationType: "",
                  default: false,
                  name: "",
                  uid: "",
                };
              }
              newLogbookData.defaultLocation = defaultLocation;
              dispatch(createNewLogBookAction(newLogbookData));
            } else {
              showToast({
                message: "Please enter a logbook name",
                type: ToastTypes.ERROR,
              });
            }
          }
        }}
        className="w-full lg:w-80 mt-16 mb-5"
      />
      <BorderButtonSquare
        text={"Cancel"}
        onClick={() => globalNavigate("/log-contacts")}
        className="w-full lg:w-80 mb-5 rounded-full"
      />
    </div>
  );
}

export default LogBookWrapper;
