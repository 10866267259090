import Header from "components/Header";
import AddLogBookWrapper from "components/LogBook/AddLogBookWrapper";
import { PotaActivation } from "components/LogBook/PotaActivation";
import { TAGS } from "constants/Config";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Activities } from "types/Models";

function AddLogBook() {
  let [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({});
  const tag = searchParams.get("tag") || "";

  const renderComponent = (tag: string) => {
    switch (tag) {
      case TAGS.POTA:
        return (
          <PotaActivation
            formData={formData}
            setFormData={setFormData}
            activityType={Activities.POTA}
          />
        );
      default:
        return <AddLogBookWrapper />;
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Logbook" />
      <div className="w-full  flex items-center justify-center">
        {renderComponent(tag)}
      </div>
    </main>
  );
}

export default AddLogBook;
