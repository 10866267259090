import TableCol from "components/Table/TableEntries/TableCol";
import React, { useEffect } from "react";
import { TableEntriesProps } from "types/Component";
import { FaSort } from "react-icons/fa";
import TableEntColCompact from "./TableColCompact";

function groupContactsByDateWithHeading(contacts: any[]) {
  try {
    const groupedContacts: any = {};

    // Group contacts by date
    contacts.forEach((contact) => {
      const dateObj = new Date(contact.contactTimeStamp.seconds * 1000);
      const dateString = dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!groupedContacts[dateString]) {
        groupedContacts[dateString] = [];
      }
      groupedContacts[dateString].push(contact);
    });

    // Convert the grouped object to an array with heading
    const result: any[] = [];
    Object.keys(groupedContacts).forEach((date) => {
      result.push({ type: "heading", heading: date });
      result.push(...groupedContacts[date]);
    });

    return result;
  } catch (error) {
    return [];
  }
}

function TableEntriesCompact({
  lists,
  editable,
  missingFields,
  guestUser,
  setDateOrder,
  showSort,
  showExchange,
  exchangeOne,
  exchangeTwo,
  exchangeThree,
  exchangeFour,
}: TableEntriesProps) {
  // console.log(lists);

  const [contacts, setContacts] = React.useState<any[]>([]);

  useEffect(() => {
    const result = groupContactsByDateWithHeading(lists);
    setContacts(result);
  }, [lists]);

  return (
    <div className="w-full overflow-scroll mt-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid text-white">
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              User
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Sent
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Rcvd
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Frequency
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Mode
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Distance
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Tags
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Conf.
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left"></th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((item: any, index: number) => {
            if (item.type === "heading") {
              return (
                <tr
                  key={`${item?.heading}_${index}`}
                  className="bg-[#1F1F3F] border-0 border-b border-slate-300 border-solid"
                >
                  <td
                    colSpan={9}
                    className="pl-2 py-3 font-[Barlow] text-sm md:text-left text-white"
                  >
                    {item?.heading}
                  </td>
                </tr>
              );
            }

            return (
              <TableEntColCompact
                key={`${item.id}_${index}`}
                item={item}
                className={`${
                  index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                } border-0 border-b border-slate-300 border-solid`}
                editable={editable}
                missingFields={missingFields}
                guestUser={guestUser}
                showExchange={showExchange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableEntriesCompact;
