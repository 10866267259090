import { FaCopy, FaSort } from "react-icons/fa6";
import { SpottingViewProps } from "types/Component";
import moment from "moment";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import { toggleSortOrder } from "helpers/Utils";

function SpottingTableView({ spotsData, onCopyPressed, setSortConfig }: SpottingViewProps) {
  const handleSort = (column: string) => {
    setSortConfig && setSortConfig((prevSortConfig) => toggleSortOrder(prevSortConfig, column));
  };

  return (
    <div className="w-full overflow-scroll px-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid text-white">
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center md:ml-4">
                <span>Date/Time</span>
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => handleSort('lastSpottedTimestamp')}
                >
                  <FaSort className="" />
                </div>
              </div>
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center md:ml-0">
                <span>Frequency</span>
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => handleSort('frequency')}
                >
                  <FaSort className="" />
                </div>
              </div>
          </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Mode</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Band</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Operator
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
            </th>
            <th className="py-3 font-[Barlow] text-sm"></th>
          </tr>
        </thead>
        <tbody>
          {spotsData?.map((item, index) => (
            <tr
              key={`${item.id}`}
              className={`bg-[#1F1F3F] border-0 border-b border-slate-300 border-solid`}
            >
                <td className="text-xs text-white font-[Barlow] font-normal py-3 md:pl-4">
                {item.lastSpottedTimestamp ? moment(item.lastSpottedTimestamp).fromNow() : 'N/A'} (
                {item.lastSpottedTimestamp ? moment(item.lastSpottedTimestamp).utc().format("HH:mm:ss") : 'N/A'}{" "}
                <span className="text-[#17F9DA]">UTC</span>)
                </td>
              <td className="text-xs text-white font-[Barlow] font-normal py-3">
                {item.frequency} MHz
              </td>
              <td className="text-xs text-white font-[Barlow] font-normal py-3">
                {item.mode}
              </td>
              <td className="text-xs text-white font-[Barlow] font-normal py-3">
                {item.band}
              </td>
              <td className="text-xs text-white font-[Barlow] font-normal py-3">
                <div className="flex items-center">
                  <span className="w-[60px]">{item.callSign}</span>
                  {item.profilePic ? (
                    <img
                      className="w-[35px] h-[35px] rounded-full ml-2 mr-2"
                      src={item.profilePic}
                      alt=""
                    />
                  ) : (
                    <UserAvatar className="w-[30px] h-[30px] lg:w-[30px] lg:h-[30px] ml-2 mr-2" />
                  )}

                  <CountryDxccFlag
                    countryCode={item?.country || ""}
                    flagCode={item?.flagCode || ""}
                  />
                  <span>
                    {item?.country} - {item?.state}
                  </span>
                </div>
              </td>
              <td
                onClick={() => {
                  onCopyPressed(item);
                }}
              >
                <FaCopy color="#fff" className="cursor-pointer" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SpottingTableView;
