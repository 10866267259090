import TableCol from "components/Table/Table/TableCol";
import { toggleSortOrder } from "helpers/Utils";
import { FaSort } from "react-icons/fa";
import { TableCustomProps } from "types/Component";

function TableCustom({ list, setSortConfig }: TableCustomProps) {
  const handleSort = (column: string) => {
    setSortConfig && setSortConfig((prevSortConfig) => toggleSortOrder(prevSortConfig, column));
  };
  return (
    <div className="w-full overflow-y-hidden">
      <table className="bg-white w-[655px] md:w-full">
        <tbody>
          <tr className="bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              Name
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white flex items-center">
              Date Created 
              <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => handleSort('timestamp')}
                >
                  <FaSort className="" />
                </div>
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              <div className="flex items-center">
                Last Contact
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => handleSort('lastContactUpdatedAt')}
                >
                  <FaSort />
                </div>
              </div>
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 text-white">
              QSO #
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 text-white">
              Action
            </th>
          </tr>
          {list
            ? list.map((item, index) => {
                return (
                  <TableCol
                    key={index}
                    className={`${
                      index % 2 ? "bg-[#151536]" : "bg-[#1F1F3F]"
                    } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                    item={item}
                  />
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default TableCustom;
