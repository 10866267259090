import CustomBorderButton from "components/Button/CustomBorderButton";
import CountryDxccFlag from "components/CountryDxccFlag";
import { UserAvatar } from "components/UserAvatar";
import { findCountryCode } from "helpers/Utils";
import useConfig from "hooks/config.hook";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { MembershipMarkerProps } from "types/Component";

const MemberMapInfo = ({ user, weekly }: MembershipMarkerProps) => {
  const {
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    getMemberStatusText,
  } = useConfig();

  return (
    <div className="p-4 w-[200px] bg-[#fff]">
      <div className="flex justify-between mb-3">
        <div className="relative">
          {user?.profilePic ? (
            <img
              className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2]"
              src={user.profilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="h-8 w-8 mr-3" />
          )}
          <div className="absolute top-[20px] left-[20px]">
            <CountryDxccFlag
              countryCode={user.country || ""}
              flagCode={user.flagCode || ""}
            />
          </div>
        </div>
        <div className="ml-3">
          <p className="font-[Play] font-normal text-[#010A41]">
            {user.firstName} - {user.callSign}
          </p>
          <div className="w-[110px]">
            <CustomBorderButton
              text={getMemberStatusText(user.membershipStatus || "FREE")}
              onClick={() => {}}
              height="h-[24px]"
              className=""
              childHeight="h-[57px]"
              childClassName="text-[12px] font-bold px-2"
              fromBg={getSubscriptionLevelButtonColor(
                "from",
                getMemberStatusText(user.membershipStatus || "FREE")
              )}
              viaBg={getSubscriptionLevelButtonColor(
                "via",
                getMemberStatusText(user.membershipStatus || "FREE")
              )}
              toBg={getSubscriptionLevelButtonColor(
                "to",
                getMemberStatusText(user.membershipStatus || "FREE")
              )}
              childBgColor={getSubscriptionLevelButtonBgColor(
                getMemberStatusText(user.membershipStatus || "FREE")
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="font-[Play] font-bold text-[14px] text-[#010A41]">
          Number of QSOs
        </p>
        <p className="font-[Play] text-[13px] font-normal text-[#010A41]">
          {weekly ? user.leaderBoardCount : user.numberOfContacts || 0}
        </p>
      </div>
      {user.foundingMemberCount ? (
        <div className="flex justify-between">
          <p className="font-[Play] text-[14px] font-bold text-[#010A41]">
            Founding Member#
          </p>
          <p className="font-[Play] text-[13px] font-normal text-[#010A41]">
            {user.foundingMemberCount}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default MemberMapInfo;
