import { useCallback, useState } from "react";
import Header from "components/Header";

import { useDispatch, useSelector } from "react-redux";
import { spottingBandList, spottingModes } from "constants/Config";

import SpottingMap from "components/GoogleMap/SpottingMap";
import SpottingTableView from "components/SpottingTableView";
import SpottingGridView from "components/SpottingGridView";
import { getSpotSelector } from "store/Reducers/spotting.reducer";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
import { SpotModel } from "types/Models";
import { globalNavigate } from "routes/GlobalRoutes";
import { LOG_CONTACT_ROUTE } from "constants/Links";
import SpottingFilters from "components/SpottingFilters";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { setAddSpotModal } from "store/Actions/logBook.actions";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import SpotRefresh from "components/SpotRefresh";
import { useSortAndFilter } from "hooks/useSortAndFilter";
import CustomGradientButton from "components/Button/CustomGradientButton";
import IconTable from "components/icons/IconTable";
import IconGrid3x3 from "components/icons/IconGrid3x3";

function Spotting() {

  const [showGridView, setShowGridView] = useState(false);
  const spotsData = useSelector(getSpotSelector);
  const spotLoading = useSelector(getIsSpottingLoadingSelector);
  const dispatch = useDispatch();

  const {
    setSortConfig,
    filters,
    setFilters,
    applyChanges,
  } = useSortAndFilter({ column: "", order: "" }, []);


  const onCopyPressed = (item: SpotModel) => {
    globalNavigate(`${LOG_CONTACT_ROUTE}`, {
      state: { spotData: item },
    });
    showToast({
      message: "Please choose a logbook you want to copy the spot to.",
      type: ToastTypes.WARN,
    });
  };

  const refreshSpots = useCallback(() => {
    applyChanges();
  }, [dispatch]);

  // const debouncedBoundChangeListener = useDebouncedCallback(() => {
  //   const selectedBands = band.filter((item) => item.enabled);
  //   const selectedModes = mode.filter((item) => item.enabled);

  //   // dispatch
  //   const filter: SpotFilters[] = [];
  //   if (selectedBands.length) {
  //     filter.push({
  //       band: selectedBands,
  //     });
  //   }
  //   if (selectedModes.length) {
  //     filter.push({
  //       mode: selectedModes,
  //     });
  //   }
  //   if (filter.length) {
  //     setFilters(filter);
  //     applyChanges(filter);
  //   } else {
  //     setFilters([]);
  //     applyChanges([]);
  //   }
  // }, 1000);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Spotting" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">

        <div className="w-full">
          <div className="bg-[#0B0B39] w-full py-5 flex flex-col items-start">
            {/* Heading */}
            <h1 className="font-[play] font-bold text-xl text-white ml-5">
              Spotting
            </h1>

            {/* Divider */}
            <div className="h-[0.5px] w-full bg-[#1b1d63] mt-3" />

            <SpottingFilters
              applyChanges={applyChanges}
              filters={filters}
              setFilters={setFilters}
            />

            {/* Divider */}
            <div className="h-[0.5px] w-full bg-[#1b1d63] mt-2 mb-3" />

            {/* Spotting Map */}
            <SpottingMap spotsData={spotsData} spotLoading={spotLoading} />

            <div className="h-[3px] w-full bg-[#85859C] mt-5 mb-2" />
            <div className="flex justify-between w-full mb-7 px-5">
              <div className="flex">
                <CustomGradientButton
                  icon={IconTable}
                  iconClass={`w-[15px] h-[15px] ${showGridView ? "text-white" : "text-[#000000]"}`}
                  text="Table View"
                  textClass={`text-[12px] ${showGridView ? "text-white" : "text-[#010a41]"} ml-2`}
                  className={`w-[130px] h-8 rounded-[3px] cursor-pointer border ${showGridView ? "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner" : "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"}`}
                  onClick={() => {
                    setShowGridView(false);
                  }}
                />
                <CustomGradientButton
                  icon={IconGrid3x3}
                  iconClass={`w-[15px] h-[15px] ${showGridView ? "text-[#000000]" : "text-white"}`}
                  text="Grid View"
                  textClass={`text-[12px] ${showGridView ? "text-[#010a41]" : "text-white"} ml-2`}
                  className={`ml-3 w-[130px] h-8 rounded-[3px] cursor-pointer border ${showGridView ? "from-[#828aff] to-[#8ae9ff] border-[#505bf9]" : "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"}`}
                  onClick={() => {
                    setShowGridView(true);
                  }}
                />
              </div>
              <div className="w-[220px]">
                <BorderButtonSecondary
                  text={`+  Add Spot`}
                  onClick={() => {
                    dispatch(setAddSpotModal(true));
                  }}
                  className="sm:mb-0 cursor-pointer"
                />
              </div>
            </div>
            <div className="ml-1">
              <SpotRefresh refreshSpots={refreshSpots} />
            </div>
            {spotsData?.length === 0 && spotLoading ? (
              <ActivityIndicator size={36} />
            ) : showGridView ? (
              <SpottingGridView
                spotsData={spotsData}
                onCopyPressed={onCopyPressed}
              />
            ) : (
              <SpottingTableView
                spotsData={spotsData}
                onCopyPressed={onCopyPressed}
                setSortConfig={setSortConfig}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Spotting;
