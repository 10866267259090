export const QSO_MANAGER_PAGE_SIZE = 10;
export const QSO_MANAGER_MAX_OFFSET = 100;
export const QSO_MAX_PAGE_JUMP_SIZE = 5;
export const QSO_IMPORTED_ONLY = "Imported Only";
export const QSO_WRL_LOGGER_ONLY = "WRL Logger Only";
export const IN_PROGRESS = "In Progress";
export const COMPLETE = "Complete";

export const ActivitiesColorMapping: Record<string, string> = {
  POTA: "#12CAA7",
  SOTA: "#FF3B30",
  IOTA: "#FFAC30",
  WWFF: "#66FF30",
};
