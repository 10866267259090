import { BorderButtonSecondaryProps } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function BorderButtonSecondary({
  text,
  onClick,
  className,
  isLoading,
  fontStyle,
  height,
  childHeight,
  childClassName,
  icon,
  selected,
  tabIndex,
}: BorderButtonSecondaryProps) {
  return (
    <div
      tabIndex={tabIndex}
      className={`bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full ${
        className ? className : ""
      } ${isLoading ? "pointer-events-none" : "cursor-pointer"} ${
        height ? height : "h-8"
      } rounded-full flex justify-center items-center text-white`}
      onClick={onClick}
    >
      <div
        className={`${childHeight ? childHeight : ""}  ${
          selected ? "bg-[#002C87]" : "bg-[#1D204A]"
        } w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center font-Play ${
          childClassName ? childClassName : "text-sm sm:text-base"
        }`}
      >
        {icon ? icon : ""}
        {isLoading ? <ActivityIndicator size={16} /> : text}
      </div>
    </div>
  );
}

export default BorderButtonSecondary;
