import React from "react";
import Switch from "react-switch";

export type ActivityFilterProps = {
  activity: any;
  index: number;
  setActivityFilter: any;
};

function ActivityFilter({
  activity,
  index,
  setActivityFilter,
}: ActivityFilterProps) {
  const onToggle = (checked: boolean) => {
    // Change the selected state of the activity
    setActivityFilter((prevFilters: any) =>
      prevFilters.map((item: any, idx: number) =>
        idx === index ? { ...item, selected: checked } : item
      )
    );
  };

  return (
    <>
      <div
        className={`cursor-pointer border flex justify-between w-[150px] py-1 px-3 rounded-lg items-center mr-2 mb-2`}
        style={{ borderColor: activity.color }}
        onClick={() => onToggle(!activity.selected)}
      >
        <div className="flex items-center">
          <img src={activity?.icon} alt="" className="w-[16px] h-[16px] mr-1" />
          <p className="text-white font-[Barlow] font-bold">{activity.name}</p>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Switch
            onChange={onToggle}
            checked={activity.selected}
            uncheckedIcon={false}
            checkedIcon={false}
            height={12}
            onColor="#7B61FF"
            width={30}
          />
        </div>
      </div>
    </>
  );
}

export default ActivityFilter;
