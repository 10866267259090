import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import Header from "components/Header";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { DefaultLocationTypes } from "constants/Config";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavigateBackButton } from "../NavigateBackButton";
import { HorizontalDivider } from "components/Divider";
import {
  SelectableButtonsGroup,
  SliderButtonGroup,
} from "components/SelectableButtonsGroup";
import Icons from "assets/Icons";
import { StreetLocation } from "./StreetLocation";
import { MapLocation } from "./MapLocation";
import { GridLocation } from "./GridLocation";
import { Checkbox } from "components/CheckBox/CheckBox";
import { useSavedLocations } from "hooks/useSavedLocations";
import { useLoadScript } from "@react-google-maps/api";
import { SavedLocation } from "types/Models";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";

const libraries: any = ["places"];

export const LocationMethods = {
  ADDRESS: "address",
  GRID: "grid",
  MAP: "map",
};

const AddNewSavedLocations = () => {
  const location = useLocation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_API!}&loading=async`,
    libraries,
    id: "google-map-script",
  });

  const {
    locationForm,
    setLocationForm,
    populateAddressForm,
    addressLoading,
    saveLocation,
    resetLocationForm,
    savedLocationLoading,
    resetPartialLocationForm,
  } = useSavedLocations();

  const userProfile = useSelector(getUserProfileSelector);
  const [currentUserLocationFetched, setCurrentUserLocationFetched] =
    useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 450);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 450);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (userProfile) {
      setLocationForm((prev) => ({
        ...prev,
        setAsHome: userProfile.savedLocationId === location?.state?.data?.id,
      }));
    }
  }, [userProfile]);

  useEffect(() => {
    if (location?.state?.edit && location?.state?.data) {
      setLocationForm((prev) => ({
        ...prev,
        locationType: location?.state?.data?.locationType,
        name: location?.state?.data?.name,
        qth: location?.state?.data?.qth,
        country: location?.state?.data?.country,
        state: location?.state?.data?.state,
        city: location?.state?.data?.city,
        gridsquare: location?.state?.data?.gridsquare,
        lat: location?.state?.data?.lat,
        lng: location?.state?.data?.lng,
        ituZone: location?.state?.data?.ituZone,
        cqZone: location?.state?.data?.cqZone,
        locationMethod:
          location?.state?.data?.locationMethod || LocationMethods.ADDRESS,
      }));
    }
  }, [location?.state?.edit, location?.state?.data]);

  const confirmAddressHandler = (address: string) => {
    populateAddressForm(address, "address");
  };

  const confirmGridHandler = (grid: string) => {
    populateAddressForm(grid, "grid");
  };

  const getCurrentUserLocationHandler = () => {
    populateAddressForm("", "currentLocation");
  };

  const mapLocationChangeHandler = (latLng: any) => {
    populateAddressForm(latLng, "map");
  };

  const renderLocationSettings = () => {
    switch (locationForm.locationMethod) {
      case LocationMethods.ADDRESS:
        return (
          <StreetLocation
            {...{
              locationForm,
              setLocationForm,
              callback: confirmAddressHandler,
              addressLoading,
              resetForm: resetPartialLocationForm,
            }}
          />
        );
      case LocationMethods.GRID:
        return (
          <GridLocation
            {...{
              locationForm,
              setLocationForm,
              callback: confirmGridHandler,
              addressLoading,
              resetForm: resetPartialLocationForm,
            }}
          />
        );
      case LocationMethods.MAP:
        return (
          <MapLocation
            {...{
              locationForm,
              setLocationForm,
              callback: mapLocationChangeHandler,
              addressLoading,
              getUserCurrentLocation: getCurrentUserLocationHandler,
              currentUserLocationFetched,
              setCurrentUserLocationFetched,
            }}
          />
        );
      default:
        return (
          <StreetLocation
            {...{
              locationForm,
              setLocationForm,
              callback: confirmAddressHandler,
              addressLoading,
            }}
          />
        );
    }
  };
  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Logbook" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="h-full w-full overflow-x-hidden">
          <NavigateBackButton />
          <div className="glassmorphism w-full h-fit pb-6 px-3 md:px-14 py-8 flex flex-col gap-6">
            <div className="flex w-full flex-col gap-2">
              <div className="flex items-center w-full">
                <TitledInputDefault
                  setText={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                  name="name"
                  title="Name"
                  placeHolder="Enter location name"
                  value={locationForm.name}
                  width={"w-full sm:w-[49%] md:w-[40.5%] xl:w-[41%]"}
                  className={"h-[36px] mt-[12px] mr-3"}
                  tabindex={20}
                  error={null}
                />
                <LogbookSettingDropdown
                  list={DefaultLocationTypes}
                  title="Location Type"
                  className="ml-5"
                  value={locationForm.locationType}
                  setValue={(value) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      locationType: value,
                    }));
                  }}
                />
              </div>

              <div className="flex flex-col gap-4 w-[100%] lg:w-[75%] xl:w-[65%]">
                <div className="font-bold text-xl text-white font-Play">
                  How do you want to set the location?
                </div>

                {isMobile ? (
                  <SelectableButtonsGroup
                    initialSelected={locationForm.locationMethod}
                    disabled={false}
                    actions={[
                      {
                        id: LocationMethods.ADDRESS,
                        icon: Icons.StreetIcon,
                        label: "Street Address",
                        tabIndex: 8,
                      },
                      {
                        id: LocationMethods.GRID,
                        icon: Icons.GridsIcon,
                        label: "Enter Grid Square",
                        tabIndex: 8,
                      },
                      {
                        id: LocationMethods.MAP,
                        icon: Icons.Location,
                        label: "Map/Find My Location",
                        tabIndex: 8,
                      },
                    ]}
                    onButtonChange={(action) => {
                      if (action && action.id) {
                        setLocationForm((prev) => ({
                          ...prev,
                          locationMethod:
                            action.id as SavedLocation["locationMethod"],
                        }));
                      }
                    }}
                  />
                ) : (
                  <SliderButtonGroup
                    initialSelected={locationForm.locationMethod}
                    disabled={false}
                    actions={[
                      {
                        id: LocationMethods.ADDRESS,
                        icon: Icons.StreetIcon,
                        label: "Street Address",
                        tabIndex: 8,
                      },
                      {
                        id: LocationMethods.GRID,
                        icon: Icons.GridsIcon,
                        label: "Enter Grid Square",
                        tabIndex: 8,
                      },
                      {
                        id: LocationMethods.MAP,
                        icon: Icons.Location,
                        label: "Map/Find My Location",
                        tabIndex: 8,
                      },
                    ]}
                    onButtonChange={(action) => {
                      if (action && action.id) {
                        setLocationForm((prev) => ({
                          ...prev,
                          locationMethod:
                            action.id as SavedLocation["locationMethod"],
                        }));
                      }
                    }}
                  />
                )}
              </div>
            </div>

            <HorizontalDivider />

            {isLoaded ? renderLocationSettings() : null}

            <div>
              <div className="flex w-full mt-16 mb-6 justify-center">
                <Checkbox
                  label="Set This As Default / Home Location"
                  name="setAsHome"
                  value={locationForm.setAsHome as any}
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      setAsHome: e.target.checked,
                    }));
                  }}
                />
              </div>

              <div className="w-full flex justify-center gap-4">
                <div className="w-[220px]">
                  <BorderButtonSecondary
                    text={
                      location?.state?.edit
                        ? "Update Location"
                        : `Save Location`
                    }
                    onClick={saveLocation}
                    className="sm:mb-0 cursor-pointer"
                    height="h-8"
                    childClassName="text-xs"
                    isLoading={savedLocationLoading || addressLoading}
                  />
                </div>
                <div className="w-[100px]">
                  <BorderButtonSecondary
                    isLoading={addressLoading}
                    text={"Clear"}
                    onClick={resetLocationForm}
                    childClassName="text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AddNewSavedLocations;
