import Icons from "assets/Icons";
import React from "react";
import { TopTabNavProps } from "types/Component";

function TopTabNav({
  selectedTabIndex,
  setSelectedTabIndex,
  firstIndexText,
  secondIndexText,
  thirdIndexText,
  openSpottingPopup,
  openMapPopup,
  showExternal = false,
  className,
}: TopTabNavProps) {
  return (
    <div className={`${className ? className : ''} flex justify-between items-center w-full`}>
      <button
        className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
          selectedTabIndex === "entries" ? "bg-[#151762]" : "bg-[#151541]"
        }`}
        onClick={() => setSelectedTabIndex("entries")}
      >
        <div className="w-full flex items-center justify-start">
          {firstIndexText}
        </div>
      </button>
      <button
        className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
          selectedTabIndex === "qso" ? "bg-[#151762]" : "bg-[#151541]"
        }`}
        onClick={() => setSelectedTabIndex("qso")}
      >
        <div className="w-full flex items-center justify-start gap-2">
          {secondIndexText}
          {/* { showExternal ? <img onClick={openMapPopup} className="h-5 w-5" src={Icons.External} alt="" /> : null} */}
        </div>
      </button>
      {thirdIndexText ? (
        <button
          className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 ${
            selectedTabIndex === "spotting" ? "bg-[#151762]" : "bg-[#151541]"
          }`}
          onClick={() => setSelectedTabIndex("spotting")}
        >
          <div className="w-full justify-between flex items-center pr-4">
            <div className="w-full flex items-center justify-start gap-2">
              {thirdIndexText}
              { showExternal ? <img onClick={openSpottingPopup} className="h-5 w-5" src={Icons.External} alt="" /> : null}
            </div>
          </div>
        </button>
      ) : null}
    </div>
  );
}

export default TopTabNav;
