import { bulkUpdateContacts } from "firebase-config";
import { showToast } from "helpers/Toast";
import { getStateData } from "helpers/Utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editContactLogBookDataAction,
  selectEditContactModalData,
  setConfirmChangesModalData,
} from "store/Actions/logBook.actions";
import {
  getIsLogBookLoadingSelector,
  getIsLogBookSavingLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getEditContactModalDataSelector,
  getLogBookListSelector,
  getConfirmChangesModalDataSelector,
} from "store/Reducers/logBook.reducer";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { ToastTypes } from "types/Component";
import { LogBookContactModel, LogBookModel } from "types/Models";

export const useEditContact = () => {
  const dispatch = useDispatch();
  const editContactModalData: any = useSelector(
    getEditContactModalDataSelector
  );
  const confirmChangesModalData = useSelector(
    getConfirmChangesModalDataSelector
  );
  const isLoading = useSelector(getIsLogBookLoadingSelector);
  const isSavingOnLoading = useSelector(getIsLogBookSavingLoadingSelector);
  const logBooksList: LogBookModel[] = useSelector(getLogBookListSelector);
  const userProfile = useSelector(getUserProfileSelector);

  const [isBulkEditLoading, setIsBulkEditLoading] = useState(false);

  const setEditContactModalData = (
    contactData:
      | LogBookContactModel
      | null
      | {
          isBulkEdit?: boolean;
          selectedRows?: any;
          qsoFilters?: any;
          totalQSOs?: number;
          allSelected?: boolean;
        }
  ) => {
    dispatch(selectEditContactModalData(contactData as any));
  };

  const setConfirmChangesModal = (data: any) => {
    if (data?.state){
      const stateData = getStateData(data?.state || "");
      if (stateData && stateData?.name && stateData?.shortCode) {
        data.stateLongName = stateData?.name;
        data.state = stateData?.shortCode;
      }
    }

    dispatch(setConfirmChangesModalData(data));
  };

  const editLogBookContact = async (
    values: LogBookContactModel,
    prevValues: LogBookContactModel
  ) => {
    let contactData: LogBookContactModel = values;
    dispatch(editContactLogBookDataAction(contactData, prevValues));
  };

  const bulkUpdateContact = async (criteria: any) => {
    try {
      setIsBulkEditLoading(true);
      const uid = userProfile?.uid;
      const { selectedRows, qsoFilters, payload } = criteria;

      if (
        typeof payload.payload === "object" &&
        Object.keys(payload.payload).length === 0
      ) {
        showToast({
          type: ToastTypes.ERROR,
          message: "Please select at least one field to update",
        });
        setIsBulkEditLoading(false);
        return;
      }

      if (!criteria) throw new Error("No criteria provided");
      if (
        selectedRows &&
        !selectedRows.length &&
        !Object.keys(qsoFilters).length &&
        !uid
      ) {
        throw new Error("No criteria provided");
      }

      const [first] = selectedRows;

      if (first === "all") {
        await bulkUpdateContacts({
          filter: {
            ...qsoFilters,
            uid,
          },
          payload,
        });
      } else {
        await bulkUpdateContacts({
          filter: {
            selectedContacts: selectedRows,
          },
          payload,
        });
      }

      setConfirmChangesModal(null);
      setEditContactModalData(null);
      setIsBulkEditLoading(false);
      showToast({
        type: ToastTypes.SUCCESS,
        message: "Contacts updated successfully",
      });
    } catch (error: any) {
      setIsBulkEditLoading(false);
      
      if (error?.code ==="functions/deadline-exceeded" || error?.code === "deadline-exceeded") {}
      else{
        showToast({
          type: ToastTypes.ERROR,
          message: error?.message || "Error updating contacts",
        });
      }
    }
  };

  return {
    editContactModalData,
    setEditContactModalData,
    logBooksList,
    editLogBookContact,
    isLoading,
    isSavingOnLoading,
    setConfirmChangesModal,
    confirmChangesModalData,
    bulkUpdateContact,
  };
};
