import React, { useEffect } from "react";

import { BsPersonCircle } from "react-icons/bs";
import { NotificationBannerProps } from "types/Component";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserNormalNotifications } from "store/Actions/notification.actions";
import { getUserNormalNotificationsSelector } from "store/Reducers/notification.reducer";
import moment from "moment";
import { UserAvatar } from "components/UserAvatar";
import { NOTIFICATION_TYPES } from "constants/Config";
import { UserNotification } from "types/Models";
import { globalNavigate } from "routes/GlobalRoutes";

const NotificationBanner = ({
  onNotificationToggle,
}: NotificationBannerProps) => {
  const dispatch = useDispatch();
  const userNormalNotifications = useSelector(
    getUserNormalNotificationsSelector
  );

  useEffect(() => {
    dispatch(fetchUserNormalNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotificationButton = (notification: UserNotification) => {
    if (
      notification.type === NOTIFICATION_TYPES.EXPORT &&
      notification?.data?.fileUrl
    ) {
      return (
        <a
          href={notification?.data?.fileUrl}
          target="_blank"
          rel="noreferrer"
          className="text-white font-[Play] text-[12px] px-2 bg-[#0243DD] mt-2 cursor-pointer"
        >
          Download Export
        </a>
      );
    } else if (notification.activityId) {
      return (
        <p
          className="text-white font-[Play] text-[12px] px-2 bg-[#0243DD] mt-2 cursor-pointer"
          onClick={() => {
            globalNavigate(
              `/log-contacts/activity-details?activity_id=${notification.activityId}`
            );
          }}
        >
          View Import
        </p>
      );
    }
  };

  return (
    <div className="w-[90%] sm:w-[80%] md:w-[40%] h-[400px] bg-[#1A1A45] border-[0.5px] border-[#17F9DA] absolute right-4 top-16 py-5 overflow-scroll">
      <div className="flex justify-between mb-5">
        <p className="font-[Play] text-white font-bold ml-4">
          Notifications (
          {userNormalNotifications.filter((notif) => !notif.seen).length})
        </p>
        <button
          className="text-white mr-4"
          onClick={() => onNotificationToggle()}
        >
          Close
        </button>
      </div>
      {userNormalNotifications.length === 0 ? (
        <div className="w-full h-full flex items-center justify-center">
          <p className="font-[Play] text-white text-center w-[50%]">
            You don't have any notifications, notifications will appear here.
          </p>
        </div>
      ) : (
        userNormalNotifications.map((notification, i) => (
          <div className="w-full" key={`notification-${i}`}>
            <div
              className={`flex items-center px-4 py-3 ${
                notification.seen ? "" : "bg-[#292852]"
              }`}
            >
              <div className="w-[20%] md:w-[15%]">
                {notification.image === "NO_PROFILE" ? (
                  <UserAvatar className="w-[40px] h-[40px] mr-3" />
                ) : (
                  <img
                    src={notification.image}
                    className="w-[40px] h-[40px] rounded-full mr-3"
                    alt=""
                  />
                )}
              </div>
              <div className="flex flex-col w-[80%] md:w-[85%]">
                <p className="font-[Play] text-white font-bold text-[15px]">
                  {notification.title}
                </p>
                <p className="font-[Play] text-white text-[12px]">
                  {notification.body}
                </p>
                <div className="flex items-center justify-between">
                  <p className="font-[Play] text-white text-[12px]">
                    {moment(new Date(notification.timestamp)).fromNow()}
                  </p>
                  {getNotificationButton(notification)}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default NotificationBanner;
