import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { db, getCountryFromCallSign } from "firebase-config";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { CoordinatesParams } from "store/Actions/profile.actions";
import useConfig from "./config.hook";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { SpotModel, SpotterUser } from "types/Models";
import { Timestamp, collection, onSnapshot, query } from "firebase/firestore";
import { Coordinates } from "types/State";
import { SPOT_ACTION_TYPE, SPOT_SOURCE, SPOT_STATUS, SPOT_TYPE } from "types/Functions";
import { createSpot, editSpot } from "store/Actions/spotting.action";
import { findBandAndModeForFrequency, getDxDataFromCountry } from "helpers/Utils";
import { Spotting } from "constants/Collections";
import { getAddSpotModalSelector } from "store/Reducers/logBook.reducer";
import { getIsSpottingLoadingSelector } from "store/Reducers/loading.reducer";
import SpottingService from "services/spotting.service";
import ProfileService from "services/profile.service";
const useSpotting = () => {
  const dispatch = useDispatch();
  const { handlePrimaryModal } = useConfig();
  const spotLoading = useSelector(getIsSpottingLoadingSelector);

  const addSpotModal = useSelector(getAddSpotModalSelector);

  const userProfile = useSelector(getUserProfileSelector);

  const [myCallSign, setMyCallSign] = useState(userProfile?.callSign);
  const [theirCallSign, setTheirCallSign] = useState(
    addSpotModal?.callSign || ""
  );
  const [myLocation, setMyLocation] = useState(
    `${userProfile?.state || userProfile?.city}, ${userProfile?.country}`
  );
  const [theirCountry, setTheirCountry] = useState(
    addSpotModal?.country || ""
  );
  const [theirState, setTheirState] = useState(addSpotModal?.state || "");
  const [theirGrid, setTheirGrid] = useState(addSpotModal?.grid || "");
  const [frequency, setFrequency] = useState(addSpotModal?.frequency || "");
  const [mode, setMode] = useState(addSpotModal?.mode || "");
  const [band, setBand] = useState(addSpotModal?.band || "");
  const [comments, setComments] = useState(addSpotModal?.comments || "");
  const [userCoordinates, setUserCoordinates] = useState<Coordinates>(
    userProfile?.coordinates || { latitude: 0, longitude: 0 }
  );

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (addSpotModal && addSpotModal.callSign) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserCountryFromCallSign = async (callSign: string) => {
    try {
      setLoading(true);
      const countryResponse: any = await getCountryFromCallSign(callSign);
      if (countryResponse && countryResponse.data) {
        setTheirCountry(countryResponse?.data?.Country || "");
        return { country: countryResponse?.data?.Country || "", state: "" };
      }
    } catch (e) {
      setTheirCountry("");
    }
    setLoading(false);
    return { country: "", state: "" };
  };
  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const onFindMyLocationClicked = async () => {
    const coor: CoordinatesParams = await fetchCoordinates();
    if (coor) {
      setUserCoordinates(coor);
    } else {
      handlePrimaryModal({
        title:
          "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
        type: "Location Permission",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: () => {
          handlePrimaryModal(null);
        },
        submitText: "Ok",
        cancelText: "Cancel",
      });
    }
  };

  const onFrequencyBlur = () => {
    try {
      if (frequency) {
        const freqNumber = parseFloat(frequency);
        const bandMode = findBandAndModeForFrequency(freqNumber);
        if (bandMode) {
          setBand(bandMode.band);
          setMode(bandMode.mode);
        } else {
          setBand("");
          setMode("");
        }
      }
    } catch (e) { }
  };

  const saveSpot = async (actionType: SPOT_ACTION_TYPE.SPOT_ME | SPOT_ACTION_TYPE.SPOT_THEM): Promise<boolean> => {
    const isSpotMe = actionType === SPOT_ACTION_TYPE.SPOT_ME;
    const callSignToUse = isSpotMe ? userProfile?.callSign || '' : theirCallSign;

    if (!validateSpotData(callSignToUse, frequency)) {
      showToast({
        message: "Please enter the required call sign and frequency",
        type: ToastTypes.ERROR,
      });
      return false;
    }

    setLoading(true);

    try {
      // Data integrity cleanup
      const { band: ensuredBand, mode: ensuredMode } = ensureBandAndMode(frequency, band, mode);
      const { country: ensuredCountry, state: ensuredState } = await ensureLocation(
        callSignToUse,
        isSpotMe ? userProfile?.country || '' : theirCountry,
        isSpotMe ? userProfile?.state || '' : theirState
      );
      const response = await ProfileService.checkCallSignAvailability(callSignToUse);
      const uid = response?.data?.uid;
      const timeStamp = Timestamp.now();
      const spotterUser: SpotterUser = {
        uid: userProfile?.uid || '',
        callSign: userProfile?.callSign || '',
        timestamp: timeStamp,
        coordinates: userCoordinates,
      };
      const dxccData = getDxDataFromCountry(ensuredCountry);
      const newSpotData: SpotModel = {
        uid: uid || '',
        callSign: callSignToUse,
        frequency,
        band: ensuredBand,
        mode: ensuredMode,
        comments,
        grid: theirGrid || '',
        spotType: isSpotMe ? SPOT_TYPE.SELF : SPOT_TYPE.OTHER,
        source: SPOT_SOURCE.WRL_SPOTTING,
        spotterCount: 1,
        logBookContactIDs: [],
        //activityNumber: activityNumber || '',
        //activityName: activityName || '',
        coordinates: userCoordinates,
        country: ensuredCountry,
        dxccNumber: dxccData?.dxccNumber || 0, 
        state: ensuredState,
        spotterList: [spotterUser],
        status: SPOT_STATUS.ACTIVE,
        deactivatedBy: '',
        lastSpottedTimestamp: timeStamp,
        timestamp: timeStamp,
      };

      await processSpot(newSpotData);

      setLoading(false);

      // Reset form fields if necessary
      resetSpottingFormFields();
      return true;
    } catch (error) {
      console.error('Error processing spot:', error);
      showToast({
        message: "An error occurred while processing the spot",
        type: ToastTypes.ERROR,
      });
      return false; 
    } finally {
      setLoading(false);
    }
  };

  // Function to reset form fields
  const resetSpottingFormFields = () => {
    setTheirCallSign('');
    setTheirCountry('');
    setTheirState('');
    setTheirGrid('');
    setFrequency('');
    setMode('');
    setBand('');
    setComments('');
  };

  const respotSpot = async () => {
    if (!myCallSign) {
      toast('Your call sign is missing.');
      return;
    }
  
    if (!addSpotModal || !addSpotModal.callSign) {
      toast('No spot selected to re-spot.');
      return;
    }
  
    if (!frequency) {
      toast('Frequency is required to re-spot.');
      return;
    }
  
    if (!mode) {
      toast('Mode is required to re-spot.');
      return;
    }
  
    try {
      setLoading(true);
  
      const existingSpot = addSpotModal as SpotModel;
      // Create a copy of the existing spotterList or initialize it
      let updatedSpotterList: SpotterUser[] = existingSpot.spotterList
        ? [...existingSpot.spotterList]
        : [];
  
      // Check if the user has already spotted this spot
      const alreadySpotted = updatedSpotterList.some(
        (spotter) => spotter.callSign === myCallSign
      );
  
      if (!alreadySpotted) {
        // Add the current user to the spotterList
        const spotterUser: SpotterUser = {
          uid: userProfile?.uid || '',
          callSign: myCallSign,
          timestamp: Timestamp.fromDate(new Date()),
          coordinates: userCoordinates,
        };
  
        updatedSpotterList.push(spotterUser);
      } else {
        toast('You have already spotted this contact.');
        setLoading(false);
        return;
      }
  
      // Update the spot data
      const updatedSpotData: SpotModel = {
        ...existingSpot,
        callSign: theirCallSign,
        frequency,
        mode,
        grid: theirGrid || existingSpot.grid,
        country: theirCountry || existingSpot.country,
        state: theirState || existingSpot.state,
        spotterCount: existingSpot.spotterCount ? existingSpot.spotterCount + 1 : 1,
        lastSpottedTimestamp: Timestamp.fromDate(new Date()),
        spotterList: updatedSpotterList,
        spotType: getSpotType(updatedSpotterList)
      };
  
      // Dispatch the action to update the spot
      dispatch(editSpot(updatedSpotData));
  
    } catch (error) {
      console.error('Error re-spotting:', error);
      showToast({
        message: 'An error occurred while re-spotting.',
        type: ToastTypes.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const processSpot = async (newSpotData: SpotModel) => {
    const callSignToCheck = newSpotData.callSign;
  
    const existingSpot = await SpottingService.checkExistingSpot(callSignToCheck);
  
    if (!existingSpot) {
      // No existing spot, create a new one
      dispatch(createSpot(newSpotData));
    } else {
      const existingFrequency = parseFloat(existingSpot.frequency);
      const newFrequency = parseFloat(newSpotData.frequency);
  
      if (isSameFrequency(existingFrequency, newFrequency)) {
        // Frequencies are the same within tolerance, update existing spot
        const updatedSpotterList = [...existingSpot.spotterList];
  
        // Add new spotter to the list if not already present
        const alreadySpotted = existingSpot.spotterList.some(
          (spotter) => spotter.callSign === userProfile?.callSign
        );
  
        if (!alreadySpotted) {
          updatedSpotterList.push({
            uid: userProfile?.uid || '',
            callSign: userProfile?.callSign || '',
            timestamp: Timestamp.fromDate(new Date()),
            coordinates: userCoordinates,
          });
        }
  
        const updatedSpot: SpotModel = {
          ...existingSpot,
          logBookContactIDs: existingSpot.logBookContactIDs || [],
          frequency: newSpotData.frequency,
          band: newSpotData.band,
          mode: newSpotData.mode,
          lastSpottedTimestamp: Timestamp.fromDate(new Date()),
          spotType: getSpotType(updatedSpotterList),
          spotterList: updatedSpotterList,
          spotterCount: existingSpot.spotterCount ? existingSpot.spotterCount + 1 : 1,
          comments: newSpotData.comments || existingSpot.comments,
          status: SPOT_STATUS.ACTIVE,
        };
  
        dispatch(editSpot(updatedSpot));
      } else {
        // Frequencies differ, deactivate old spot and create new spot
        if (existingSpot.id) {
          await SpottingService.markSpotAsInactive(existingSpot.id, userProfile?.callSign || '');
        }
        dispatch(createSpot(newSpotData));
      }
    }
  };

  const spotCollectionListener = () => {
    const unsubscribe = onSnapshot(
      query(collection(db, Spotting)),
      (snapshot) => {
        // console.log("SPOTTING REFRESHED");
        // dispatch(fetchSpot([], false));
      }
    );
    return unsubscribe;
  };

  // Helper function to validate spot data (module scope or within hook)
  const validateSpotData = (callSign: string, frequency: string): boolean => {
    return Boolean(callSign && frequency);
  };

  // Helper function to ensure band and mode (module scope or within hook)
  const ensureBandAndMode = (
    frequency: string,
    band: string,
    mode: string
  ): { band: string; mode: string } => {
    if (!band || !mode) {
      const bandMode = findBandAndModeForFrequency(parseFloat(frequency));
      return {
        band: band || bandMode?.band || '',
        mode: mode || bandMode?.mode || '',
      };
    }
    return { band, mode };
  };

  // Helper function to fetch location using call sign
  const ensureLocation = async (
    callSign: string,
    country: string | undefined | null,
    state: string | undefined | null
  ): Promise<{ country: string; state: string }> => {
    if (!country || !state) {
      const locationData = await getUserCountryFromCallSign(callSign);
      return {
        country: country || locationData.country || '',
        state: state || locationData.state || '',
      };
    }
    return { country, state };
  };

  // Helper function to compare frequencies within a tolerance (module scope or within hook)
  const isSameFrequency = (
    freq1: number,
    freq2: number,
    tolerance: number = 0.005
  ): boolean => {
    return Math.abs(freq1 - freq2) <= tolerance;
  };

  const getSpotType = (spotterList: SpotterUser[]): SPOT_TYPE => {
    const hasSelf = spotterList.some(
      (spotter) => spotter.callSign === userProfile?.callSign
    );
    const hasOthers = spotterList.some(
      (spotter) => spotter.callSign !== userProfile?.callSign
    );
  
    if (hasSelf && hasOthers) return SPOT_TYPE.BOTH;
    if (hasSelf) return SPOT_TYPE.SELF;
    return SPOT_TYPE.OTHER;
  };

  return {
    myCallSign,
    setMyCallSign,
    theirCallSign,
    setTheirCallSign,
    myLocation,
    setMyLocation,
    theirCountry,
    setTheirCountry,
    theirState,
    setTheirState,
    theirGrid,
    setTheirGrid,
    frequency,
    setFrequency,
    mode,
    setMode,
    comments,
    setComments,
    getUserCountryFromCallSign,
    loading,
    onFindMyLocationClicked,
    saveSpot,
    onFrequencyBlur,
    spotCollectionListener,
    editMode,
    respotSpot,
    spotLoading,
    userProfile,
  };
};

export default useSpotting;
