import Icons from "assets/Icons";
import { QuickSettingItem } from "./QuickSettingItem";
import { useState } from "react";
import {
  AntennaModal,
  CallSignModal,
  LocationModal,
  RadioModal,
} from "./Modals";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { LogBookStyleModal } from "./Modals/LogBookStyleModal";
import { getCurrentDateString, getCurrentUTCtime } from "helpers/Date/Time";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { UserProfile } from "types/Models";
import { useEditContact } from "hooks/useEditContact.hook";
import { LogbookModal } from "./Modals/LogbookModal";
import { Form, Formik, useFormikContext } from "formik";
import { TimeQuickSettingItem } from "./TimeQuickSettingItem";
import { ContestModal } from "./Modals/ContestModal";
import { PotaModal } from "../../LogBookQuickSettings/Modals";

export const QuickSettings = () => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { savedLocations, radios, antennas } = useLogbookSetting();
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);
  const { editContactModalData, logBooksList } = useEditContact();
  const { values } = useFormikContext<any>();

  const renderModals = (item: any) => {
    if (!item) return null;

    switch (item.id) {
      case 1:
        return <CallSignModal onClose={() => setSelectedItem(null)} />;
      case 2:
        return <LocationModal onClose={() => setSelectedItem(null)} />;
      case 3:
        return <RadioModal onClose={() => setSelectedItem(null)} />;
      case 4:
        return <AntennaModal onClose={() => setSelectedItem(null)} />;
      case 5:
        return <LogBookStyleModal onClose={() => setSelectedItem(null)} />;
      case 7:
        return (
          <PotaModal
            onClose={() => setSelectedItem(null)}
            selectedContact={values}
          />
        );
      case 8:
        return <LogbookModal onClose={() => setSelectedItem(null)} />;
      case 9:
        return <ContestModal onClose={() => setSelectedItem(null)} />;
      default:
        return null;
    }
  };

  const renderCallSignItem = () => {
    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 1,
          icon: Icons.AntennaIcon,
          label: values?.myCallSign || userProfile?.callSign || "Call Sign",
        }}
      />
    );
  };

  const renderLocationItem = () => {
    let locationDetails = undefined;

    if (editContactModalData?.mySavedLocationId) {
      locationDetails = savedLocations.find(
        (location: any) =>
          location.id === editContactModalData?.mySavedLocationId
      );
    }

    if (values?.mySavedLocationId) {
      locationDetails = savedLocations.find(
        (location: any) => location.id === values?.mySavedLocationId
      );
    }

    const label = locationDetails?.name
      ? locationDetails?.name
      : editContactModalData?.userGrid || "Location";

    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 2,
          icon: Icons.PinIcon,
          label: label as string,
        }}
      />
    );
  };

  const renderParksItem = () => {
    const label =
      editContactModalData?.primaryActivity ||
      editContactModalData?.activitiesData
        ? editContactModalData?.activitiesData &&
          editContactModalData?.activitiesData[0].reference
        : Object.keys(editContactModalData?.myParks || {}).join(", ");

    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 7,
          icon: Icons.PinIcon,
          label: label || "My Activities",
        }}
      />
    );
  };

  const renderLogbookStyleItem = () => {
    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 5,
          icon: Icons.SingleTreeIcon,
          label: "Logbook Style",
        }}
      />
    );
  };

  const renderRadioItem = () => {
    const selectedRadio = radios.find(
      (radio) => radio.id === values?.myRadioId
    );

    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 3,
          icon: Icons.RadioIcon,
          label: values?.myRadio || selectedRadio?.name || "Radio",
        }}
      />
    );
  };

  const renderAntennaItem = () => {
    const selectedAntenna = antennas.find(
      (antenna) => antenna.id === values?.myAntennaId
    );

    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 4,
          icon: Icons.AntennaIcon,
          label: values?.myAntenna || selectedAntenna?.name || "Antenna",
        }}
      />
    );
  };

  const renderLogbookItem = () => {
    const selectedLogbook = logBooksList.find(
      (logbook) => logbook.id === values?.logBookId
    );

    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 8,
          icon: Icons.LogbookIcon,
          label: selectedLogbook?.name || "Logbook",
        }}
      />
    );
  };

  const renderContestItem = () => {
    return (
      <QuickSettingItem
        onItemClick={setSelectedItem}
        data={{
          id: 9,
          icon: Icons.ContestIcon,
          label: "Contest Settings",
        }}
      />
    );
  };

  return (
    <div className="w-full flex flex-wrap justify-between bg-blue-900 bg-opacity-40 px-2 py-1">
      <div className="flex flex-row flex-wrap gap-x-5">
        {renderLogbookItem()}
        {renderCallSignItem()}
        {renderParksItem()}
        {renderLocationItem()}
        {renderRadioItem()}
        {renderAntennaItem()}
        {renderContestItem()}
        {/* {renderLogbookStyleItem()} */}
      </div>
      <div>
        <TimeQuickSettingItem onItemClick={setSelectedItem} />
        {/* <QuickSettingItem
          onItemClick={setSelectedItem}
          data={{
            id: 6,
            icon: Icons.ClockIcon,
            label: `${getCurrentDateString()} | ${getCurrentUTCtime()} z`,
          }}
        /> */}
      </div>
      {renderModals(selectedItem)}
    </div>
  );
};
