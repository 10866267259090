import React, { useEffect, useState } from "react";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import Filter, { FilterConfig } from "components/Filter";
import { useSearchParams } from "react-router-dom";
import CustomMultiSelectDropdown from "components/DropDown/DropDown/CustomMultiSelectDropDown";
import { convertBandValue } from "helpers/Utils";
import ToggleSwitch from "components/Button/ToggleSwitchButton";
import StationLocationFilter from "./StationLocationFilter";

const filtersConfig: FilterConfig[] = [
  { title: "Station Location", field: "stationLocation", type: "custom", customComponent: StationLocationFilter, width: 260, height: 160 },
  // { title: "Operating Near Me", field: "operatingNearMe", type: "boolean", hasOptions: false },
  { title: "Spotter Near You", field: "spotterNearYou", type: "boolean", hasOptions: false },
  // { title: "Logged", field: "logged", type: "boolean", hasOptions: false },
  { title: "WRL Only", field: "wrlOnly", type: "boolean", hasOptions: false },

];

function SpottingFilters({
  applyChanges,
  updateUrl = true,
  setFilters,
  className
  // }: SpottingFiltersProps) {
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: any }>({ logged: false });
  const [appliedFilters, setAppliedFilters] = useState<FilterConfig[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterConfig | null>(null);
  const [filterConfig, setFiltersConfig] = useState<FilterConfig[]>(filtersConfig);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  // State to handle dropdown toggle for given lists
  const [dropdownVisible, setDropdownVisible] = useState<{ [key: string]: boolean }>({
    columns: false,
    filters: false,
  });

  // Initialize filters from URL on component load
  useEffect(() => {
    const initializeFiltersFromURL = async () => {
      const params = Object.fromEntries([...searchParams.entries()]); // Get all URL parameters
      const initialFilters = { ...activeFilters };  // Clone existing filters

      // Set default logged value to false if not present in URL
      initialFilters["logged"] = params.hasOwnProperty("logged") 
        ? params.logged === 'true' 
        : false;

      // Populate Dynamic Filters from `filterConfig`
      [
        ...filterConfig,
        { field: "band", type: "multiSelect" },
        { field: "mode", type: "multiSelect" }
      ].forEach((filter) => {
        if (params[filter.field]) {
          if (filter.type === "boolean") {
            initialFilters[filter.field] = params[filter.field] === 'true';
          } else if (filter.type === "multiSelect" || filter.type === "multiBoolean" || filter.type === "autoComplete") {
            initialFilters[filter.field] = params[filter.field].split(',');
          } else if (filter.type === "comparison") {
            const [operator, value] = params[filter.field].split('|');
            initialFilters[filter.field] = [operator, value];
          }
          else {
            initialFilters[filter.field] = params[filter.field];
          }
        }
      });


      // Special handling for "stationLocation" combining `myCountry` and `theirState`
      if (params.myCountry || params.theirState) {
        initialFilters["myCountry"] = params.myCountry ? params.myCountry.split(',') : [];
        initialFilters["theirState"] = params.theirState || '';
      }

      setAppliedFilters(convertToAppliedFilters(initialFilters, [] as any));
      // Add remaining URL params to activeFilters
      Object.keys(params).forEach((key) => {
        if (!initialFilters[key] && params[key] !== 'false') {
          initialFilters[key] = params[key];
        }
      });

      setActiveFilters(initialFilters);
    };
    if (searchParams.size)
      initializeFiltersFromURL();
  }, []);

  // Synchronize activeFilters with URL params whenever they change
  useEffect(() => {
    if (updateUrl) {
      const params = new URLSearchParams();

      // Iterate through `activeFilters` and add each filter to the URL
      Object.entries(activeFilters).forEach(([key, value]) => {
        if (value && key != 'stationLocation') {
          if (Array.isArray(value)) {
            params.set(key, value.join(','));
          } else {
            params.set(key, value.toString());
          }
        }
      });
      setSearchParams(params); // Update the URL
    }
  }, [activeFilters]);

  useEffect(() => {
    if (Object.keys(activeFilters).length === 0) {
      applyChanges([]);
      setFilters([]);
      return;
    }

    const filterMappings: Record<string, (value: any) => Record<string, any>> = {
      band: (value: string[]) => ({ band: value.map(itm => ({ name: itm })) }),
      mode: (value: string[]) => ({ mode: value.map(itm => ({ name: itm })) }),
      dxOnly: (value: boolean) => ({ dxOnly: value }),
      potaOnly: (value: boolean) => ({ potaOnly: value }),
      wrlOnly: (value: boolean) => ({ wrlOnly: value }),
      logged: (value: boolean) => ({ logged: value }),
      operatingNearMe: (value: boolean) => ({ operatingNearMe: value }),
      spotterNearYou: (value: boolean) => ({ spotterNearYou: value }),
      myCountry: (value: string[]) => ({ 
        myCountry: value.map((itm, index) => ({ name: itm, id: index + 1 })) 
      }),
      theirState: (value: string) => ({ theirState: value })
    };

    const filtersToApply = Object.entries(activeFilters)
      .filter(([key, value]) => (value || key === 'logged') && key in filterMappings)
      .map(([key, value]) => filterMappings[key as keyof typeof filterMappings](value));

    applyChanges(filtersToApply);
    setFilters(filtersToApply);
  }, [activeFilters]);

  const convertToAppliedFilters = (filters: any, dynamicOptions: { [key: string]: any[] }) => {
    const applied = [];

    for (const key in filters) {
      if (filters[key] && key !== 'dxOnly' && key !== 'potaOnly' && key !== 'logged') {
        // Special handling for combined filter "stationLocation"
        if (key === "myCountry" || key === "theirState") {
          // Check if "stationLocation" already exists in applied filters
          let combinedFilter: any = applied.find(f => f.field === "stationLocation");
          const countryValues = key === "myCountry" ? filters[key] : combinedFilter?.value?.myCountry || [];
          const regionValue = key === "theirState" ? filters[key] : combinedFilter?.value?.theirState || '';

          if (combinedFilter) {
            combinedFilter.value = { myCountry: countryValues, theirState: regionValue };
            combinedFilter.displayValue = `Country(s): ${countryValues.join(", ")} | Region: ${regionValue}`;
          } else {
            applied.push({
              field: "stationLocation",
              title: "Station Location",
              value: { myCountry: countryValues, theirState: regionValue },
              displayValue: `Country(s): ${countryValues.join(", ")} | Region: ${regionValue}`,
              hasOptions: true,
            });
          }
        } else {
          // Determine the display title from filterConfig or custom logic
          const config = filterConfig.find((f) => f.field === key);
          const title = config ? config.title : key;

          // Check if the field has dynamic options available
          let displayValue = filters[key];
          if (dynamicOptions[key]) {
            // Map the value to its corresponding label using dynamicOptions
            const labelMap = Object.fromEntries(dynamicOptions[key].map((opt) => [opt.id, opt.name]));
            displayValue = Array.isArray(filters[key])
              ? filters[key].map((v: string) => labelMap[v] || v).join(", ")
              : labelMap[filters[key]] || filters[key];
          }
          applied.push({
            field: key,
            title,
            value: filters[key], // Keep value for backend usage
            options: dynamicOptions[key] || [], // Add dynamic options for filter
            displayValue,         // Add display value for showing in UI
            hasOptions: config?.hasOptions || false,
          });
        }
      }
    }

    return applied;
  };

  // Toggle dropdown visibility for a particular list
  const toggleDropdown = (listName: string, flag?: boolean) => {
    setDropdownVisible((prevState) => ({
      ...prevState,
      [listName]: flag || !prevState[listName], // Toggle the visibility of the clicked list
    }));
  };

  const toggleAllDropdowns = (exceptKey?: string) => {
    setDropdownVisible((prevState) => {
      // Iterate over each key and set to false, except for the specified key
      const updatedState = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === exceptKey ? prevState[key] : false;
        return acc;
      }, {} as Record<string, boolean>);

      return updatedState;
    });
  };

  const updateFilter = (field: string, value: any) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleSelectFilter = async (filter: FilterConfig) => {
    // Remove initial values for stationLocation filter so it doesn't show up in the fresh modal 
    if(filter.field === 'stationLocation') {
      delete filter.initialValues;
    }

    if (filter.type === "boolean" && !filter.hasOptions) {
      // If it's a direct boolean filter, apply immediately
      handleApplyFilter(filter.field, true);
    } else {
      setSelectedFilter(filter); // Otherwise, show the second modal
    }
    // setShowMainModal(false);
    toggleDropdown('filters', false);
  };

  const handleApplyFilter = (field: string, value: any) => {
    // If value is not set (null, undefined, empty string, or empty array), remove the filter
    if (!value || (Array.isArray(value) && value.length === 0)) {
      handleRemoveFilter(field);
      return;
    }

    // Find the index of the existing filter
    const existingFilterIndex = appliedFilters.findIndex((f) => f.field === field);

    // Update the applied filters
    const newFilters = [...appliedFilters];
    if (existingFilterIndex !== -1) {
      newFilters[existingFilterIndex] = { field, value, title: selectedFilter?.title || field };
    } else {
      newFilters.push({ field, value, title: selectedFilter?.title || field });
    }

    setAppliedFilters(newFilters);
    updateFilter(field, value);
    setSelectedFilter(null);
  };

  const handleRemoveFilter = (field: string) => {
    setActiveFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (field === 'stationLocation') {
        // Remove both `myCountry` and `theirState` when removing `stationLocation`
        delete updatedFilters['myCountry'];
        delete updatedFilters['theirState'];
      } else {
        // Remove individual field
        delete updatedFilters[field];
      }

      return updatedFilters;
    });

    setAppliedFilters((prev) => {
      if (field === 'stationLocation') {
        // Remove the combined filter and any individual entries related to it
        return prev.filter((f) => f.field !== 'myCountry' && f.field !== 'theirState' && f.field !== 'stationLocation');
      }
      // Remove individual field from applied filters
      return prev.filter((f) => f.field !== field);
    });
  };

  const handleEditFilter = (filter: FilterConfig, values: any, event: React.MouseEvent<HTMLSpanElement>) => {
    const container = document.getElementById('filter-section');
    const rect = container?.getBoundingClientRect();

    // Adjust for the container offset and scroll position
    const topOffset = event.clientY - (rect?.top || 0);
    const leftOffset = event.clientX - (rect?.left || 0);

    setModalPosition({
      top: topOffset + window.scrollY,
      left: leftOffset + window.scrollX,
    });

    setSelectedFilter(null);

    // Slight delay to ensure previous modal cleanup before opening a new one
    setTimeout(() => {
      if (filter?.field === 'stationLocation' && (searchParams.has('myCountry') || searchParams.has('theirState'))) {
        // Extract existing values
        const { myCountry = [], theirState = '' } = activeFilters;
        filter.initialValues = {
          myCountry,
          theirState,
        };

      } 
      setSelectedFilter(filter);
    }, 0);
  };

  const handleSelectionChange = (selectedKeys: string[], context?: string) => {
    handleApplyFilter(context || '', selectedKeys);
  };

  const columns: any = AllBandsOption.map(itm => ({ label: convertBandValue(itm.name, itm.unit) + itm.unit, key: itm.name }));
  const modes: any = Modes.map(itm => ({ label: itm, key: itm }));
  return (

    <>
      {/* Filter Section */}
      <div
        id="filter-section"
        className={`flex items-center justify-start w-full px-5 space-y-2 md:space-y-0 md:space-x-2 no-select ${className ? className : ""}`}
      >
        {/*Filters */}
        <Filter
          appliedFilters={appliedFilters}
          filterConfig={filterConfig}
          setFiltersConfig={setFiltersConfig}
          dropdownVisible={dropdownVisible}
          toggleDropdown={toggleDropdown}
          toggleAllDropdowns={toggleAllDropdowns}
          handleSelectFilter={handleSelectFilter}
          selectedFilter={selectedFilter}
          handleRemoveFilter={handleRemoveFilter}
          handleApplyFilter={handleApplyFilter}
          modalPosition={modalPosition}
          setSelectedFilter={setSelectedFilter}
          handleEditFilter={handleEditFilter}
        />
      </div>
      {/* Divider */}
      <div className="h-[0.5px] w-full bg-[#1b1d63] mb-2" />
      {/* Dropdown & Toggle Section - Bands, Modes Dropdowns & DX/POTA Only Toggle Switches*/}
      <div className="flex flex-wrap items-center justify-start w-full px-5 gap-4 md:gap-4">
        <div className="w-full md:w-auto flex flex-wrap gap-4">
          <CustomMultiSelectDropdown
        options={columns}
        label={activeFilters.band?.length > 0 ? activeFilters.band.length + 'x Bands' : "All Bands"}
        context="band"
        selectedKeys={activeFilters.band || []}
        onChange={handleSelectionChange}
        className="w-full sm:w-auto"
          />
          <CustomMultiSelectDropdown
        options={modes}
        label={activeFilters.mode?.length > 0 ? activeFilters.mode.length + 'x Modes' : "All Modes"}
        context="mode"
        selectedKeys={activeFilters.mode || []}
        onChange={handleSelectionChange}
        className="w-full sm:w-auto"
          />
        </div>

        <div className="flex flex-wrap gap-4 w-full md:w-auto">
          <ToggleSwitch
        label="DX Only"
        checked={!!activeFilters.dxOnly}
        onChange={() => {
          setActiveFilters((prevFilters) => ({
            ...prevFilters,
            dxOnly: !prevFilters.dxOnly,
          }));
        }}
        height={12}
        width={25}
        offColor=""
          />

          <ToggleSwitch
        label="POTA Only"
        checked={!!activeFilters.potaOnly}
        onChange={() => {
          setActiveFilters((prevFilters) => ({
            ...prevFilters,
            potaOnly: !prevFilters.potaOnly,
          }));
        }}
        height={12}
        width={25}
        offColor=""
          />

          <ToggleSwitch
        label="Not Logged"
        checked={!activeFilters.logged}
        onChange={() => {
          setActiveFilters((prevFilters) => ({
            ...prevFilters,
            logged: !prevFilters.logged,
          }));
        }}
        height={12}
        width={25}
        offColor=""
          />
        </div>
      </div>
    </>
  );
}

export default SpottingFilters;
