import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useLogBookStats } from "hooks/useLogBookStats.hook";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedLogBookSelector } from "store/Reducers/logBook.reducer";

export const LogBookStats = () => {
  const { getLogBookAnalytics, logBookAnalytics, loading } = useLogBookStats();
  const selectedLogbook = useSelector(getSelectedLogBookSelector);

  useEffect(() => {
    if (selectedLogbook?.uid && selectedLogbook?.id) {
      getLogBookAnalytics(selectedLogbook?.id, selectedLogbook?.uid);
    }
  }, [selectedLogbook]);

  if (!logBookAnalytics && !loading) return null;

  const stats: any = logBookAnalytics;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 text-white w-full justify-between gap-4 mt-6 text-xs">
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 bg-[#151541] rounded-[5px] items-center">
        <img src={Icons.QsoPerMinIcon} alt="icon" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="text-[#17F9DA] font-Play font-bold">
              {stats?.averageQSOsPerHour?.toFixed(2)} QSO / hour
            </div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="text-[#17F9DA]">{stats?.lastQSODateTime}</div>{" "}
              since last QSO
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 bg-[#151541] rounded-[5px] items-center">
        <img src={Icons.LocationPinIcon} alt="icon" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="flex flex-row font-Play font-bold gap-1">
              DX Entities worked:<div className="text-[#17F9DA]">{stats?.dxccEntitiesWorkedCount}</div>
            </div>
            <div className="flex flex-row font-Play font-bold gap-1">
            Gridsquares worked:<div className="text-[#17F9DA]">{stats?.gridsquaresWorkedCount}</div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 bg-[#151541] rounded-[5px] items-center">
        <img src={Icons.DistanceIcon} alt="icon" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="font-Play font-bold">Longest Contact</div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="text-[#17F9DA]">
                {stats?.maxDistance?.distance?.toFixed(1)}
              </div>{" "}
              {stats?.maxDistance?.theirCallsign}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-1 flex-row gap-4 px-6 py-3 bg-[#151541] rounded-[5px] items-center">
        <img src={Icons.RankIcon} alt="icon" />
        {loading ? (
          <div className="flex flex-col gap-0">
            <ActivityIndicator size={12} />
          </div>
        ) : (
          <div className="flex flex-col gap-0">
            <div className="flex flex-row font-Play font-bold gap-1">
              Your QSOs Today:
              {/* <div className="text-[#17F9DA]">-</div> */}
            </div>
            <div className="flex flex-row gap-1 font-Play font-bold">
              <div className="text-[#17F9DA]">{stats?.totalQsosToday} QSOs</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
