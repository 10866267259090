import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import TitledDefaultCountryPicker from "components/Inputs/TitledDefaultCountryPicker";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import { LocationMappings } from "constants/LocationMappings";
import ReactCountryFlag from "react-country-flag";
import { findCountryCode } from "helpers/Utils";
import { VerticalDivider } from "components/Divider";
import { LocationSource } from "components/DefaultLocationSettings";
import TitledInput from "components/Inputs/TitledInput";
import { Form, Formik, useFormikContext } from "formik";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useEditContact } from "hooks/useEditContact.hook";

type ModalProps = {
  onClose: () => void;
};

export const LocationModal: FC<ModalProps> = ({ onClose }) => {
  const {
    savedLocationOptions,
    findLocation,
    locationForm,
    setLocationForm,
    locationLoading,
    savedLocations
  } = useLogbookSetting();

  const { editContactModalData, setEditContactModalData } = useEditContact();
  const { values, setValues } = useFormikContext<any>();

  const [defaultLocation, setDefaultLocation] = useState<string>(
    editContactModalData?.mySavedLocationId || ""
  );

  const [canEdit, setCanEdit] = useState(true);
  const [locationSource, setLocationSource] = useState(LocationSource.NONE);
  const [locationOption, setLocationOption] = useState(1);

  useEffect(() => {
    if (!editContactModalData?.mySavedLocationId) {
      setLocationOption(2);
      setLocationForm((prev) => ({
        ...prev,
        locationCountry: editContactModalData?.myCountry || "",
        locationState: editContactModalData?.myState || "",
        locationGrid: editContactModalData?.userGrid || "",
        locationLat: (editContactModalData?.myCoordinates?.latitude ||
          "") as string,
        locationLong: (editContactModalData?.myCoordinates?.longitude ||
          "") as string,
      }));
    }else if (editContactModalData?.mySavedLocationId){
      const locDetails = savedLocations.find(
        (location) => location.id === values?.mySavedLocationId
      );
      if (locDetails){
        setLocationForm((prev) => ({
          ...prev,
          locationCountry: locDetails?.country || "",
          locationState: locDetails?.state || "",
          locationGrid: locDetails?.gridsquare || "",
          locationLat: locDetails?.lat as string,
          locationLong: locDetails?.lng as string,
        }));
      }

    }
  }, [editContactModalData]);

  const onSaveClickHandler = () => {
    setValues((prev: any) => ({
      ...prev,
      mySavedLocationId: locationOption === 1 ? defaultLocation : "",
      myCountry: locationForm?.locationCountry,
      myState: locationForm?.locationState,
      userGrid: locationForm?.locationGrid,
      myCoordinates: {
        latitude: parseFloat(locationForm?.locationLat),
        longitude: parseFloat(locationForm?.locationLong),
      },
    }));

    onClose();
  };

  const onEditClickHandler = () => {
    setCanEdit(false);
  };

  useEffect(() => {
    if (values?.mySavedLocationId) {
      setLocationOption(1);
      setDefaultLocation(values?.mySavedLocationId);
    }
  }, [values?.mySavedLocationId]);

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[14%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[10%] md:px-[6%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col gap-4 w-full">
                <SelectableButtonsGroup
                  initialSelected={
                    editContactModalData?.mySavedLocationId ? 1 : 2
                  }
                  actions={[
                    {
                      id: 1,
                      label: "Saved Location",
                    },
                    {
                      id: 2,
                      label: "Custom Location",
                    },
                  ]}
                  onButtonChange={(action) => {
                    setLocationOption((action?.id || 1) as number);
                  }}
                />
                <div className="flex flex-row flex-wrap">
                  {locationOption === 1 ? (
                    <LogbookSettingDropdown
                      list={savedLocationOptions}
                      title="Saved Locations:"
                      value={defaultLocation}
                      setValue={(value) => {
                        if (value === "add") {
                          setEditContactModalData(null);
                          globalNavigate("/log-contacts/saved-locations");
                        } else {
                          setDefaultLocation(value);

                          const locDetails = savedLocations.find(
                            (location) => location.id === value
                          );
                          
                          if (locDetails){
                            setLocationForm((prev) => ({
                              ...prev,
                              locationCountry: locDetails?.country || "",
                              locationState: locDetails?.state || "",
                              locationGrid: locDetails?.gridsquare || "",
                              locationLat: locDetails?.lat as string,
                              locationLong: locDetails?.lng as string,
                            }));
                          }
                        }
                      }}
                      className="w-[80%] sm:w-[60%] md:w-[60%]"
                    />
                  ) : null}

                  {locationOption === 2 ? (
                    <>
                      {canEdit ? (
                        <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-full">
                          <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                            <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                              <div className="flex flex-row items-center gap-1">
                                <div>
                                  {locationForm?.locationCountry ? (
                                    <ReactCountryFlag
                                      countryCode={
                                        findCountryCode({
                                          name:
                                            locationForm?.locationCountry || "",
                                        })?.alpha2
                                      }
                                      svg
                                      style={{
                                        width: "3em",
                                        height: "1.2em",
                                      }}
                                      title={
                                        locationForm?.locationCountry || ""
                                      }
                                    />
                                  ) : null}
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">Country:</div>
                                  <div className=" max-w-[100px]">
                                    {locationForm?.locationCountry}
                                  </div>
                                </div>
                              </div>
                              <VerticalDivider />
                              <div className="flex flex-row items-center gap-3">
                                <div>
                                  <img src={Icons.MapIcon} alt="" />
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">State:</div>
                                  <div>{locationForm?.locationState}</div>
                                </div>
                              </div>
                              <VerticalDivider />
                              <div className="flex flex-row items-center gap-3">
                                <div>
                                  <img src={Icons.GridsIcon} alt="" />
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">Grid:</div>
                                  <div>{locationForm?.locationGrid}</div>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center gap-4">
                              <VerticalDivider />
                              <img
                                className="cursor-pointer"
                                src={Icons.EditPenIcon}
                                alt=""
                                onClick={onEditClickHandler}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col w-full">
                          <div className="flex flex-row text-white px-3 py-1 bg-[#0D1E5D] gap-6  overflow-x-auto whitespace-nowrap">
                            <div
                              className="flex flex-row gap-2 cursor-pointer"
                              onClick={() => findLocation()}
                            >
                              <img src={Icons.MyLocation} alt="" />
                              <div className="text-sm font-Play">
                                Find My Location
                              </div>
                            </div>

                            {/* <div className="flex flex-row gap-2">
                              <img src={Icons.MapCyan} alt="" />
                              <div className="text-sm font-Play">
                                Set on Map
                              </div>
                            </div> */}

                            {/* {Object.values(selectedLogbook?.myParks || {}).map(
                              (park, index) => (
                                <div
                                  className="flex flex-row gap-2 cursor-pointer"
                                  key={index}
                                  onClick={() => setLocationFromPark(park)}
                                >
                                  <img src={Icons.ParkCyan} alt="" />
                                  <div className="text-sm font-Play">
                                    {park?.reference}
                                  </div>
                                </div>
                              )
                            )} */}
                          </div>
                          <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-full">
                            <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                              <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                                <div className="flex flex-col lg:flex-row items-center gap-1">
                                  <Formik
                                    initialValues={{}}
                                    onSubmit={() => {}}
                                  >
                                    <Form className="flex flex-col lg:flex-row items-center gap-1">
                                      <TitledDefaultCountryPicker
                                        setText={(val) => {
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationCountry: val,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="country"
                                        title="Country"
                                        placeHolder="Country"
                                        value={
                                          LocationMappings[
                                            `${locationForm?.locationCountry}`
                                          ] || locationForm?.locationCountry
                                        }
                                        className={
                                          "bg-white bg-opacity-10 h-[36px]"
                                        }
                                        tabindex={8}
                                        width="w-[80%] lg:w-[22%]"
                                        error={undefined}
                                        containerClass="mb-[20px]"
                                      />
                                      {locationForm?.locationCountry ? (
                                        <TitledDefaultRegionPicker
                                          setText={(val) => {
                                            setLocationForm((prev) => ({
                                              ...prev,
                                              locationState: val,
                                            }));
                                            setLocationSource(
                                              LocationSource.CUSTOM
                                            );
                                          }}
                                          name="state"
                                          title="State"
                                          placeHolder="State"
                                          tabindex={9}
                                          width="w-[80%] lg:w-[22%]"
                                          className={
                                            "bg-white bg-opacity-10 h-[36px]"
                                          }
                                          value={locationForm?.locationState}
                                          error={undefined}
                                          country={
                                            LocationMappings[
                                              `${locationForm?.locationCountry}`
                                            ] || locationForm?.locationCountry
                                          }
                                          containerClass="mb-[20px]"
                                        />
                                      ) : null}
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationGrid: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="grid"
                                        title="Grid"
                                        value={locationForm?.locationGrid}
                                        placeHolder="Grid"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[15%]"
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationLat: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="latitude"
                                        title="Latitude"
                                        value={locationForm?.locationLat}
                                        placeHolder="latitude"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[16%]"
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationLong: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="longitude"
                                        title="Longitude"
                                        value={locationForm?.locationLong}
                                        placeHolder="longitude"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[16%]"
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                    </Form>
                                  </Formik>
                                </div>
                                <div className="flex items-center justify-center gap-4">
                                  <VerticalDivider />
                                  {locationLoading ? (
                                    <ActivityIndicator size={16} />
                                  ) : (
                                    <img
                                      className="cursor-pointer"
                                      src={Icons.SaveIcon}
                                      alt=""
                                      onClick={() => setCanEdit(true)}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={locationLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
