import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPortalSession,
  createSubscriptionSession,
  fetchSubscriptionProducts as fetchSubscriptionProductsAction,
  updateAutoRenew,
} from "store/Actions/subscription.actions";
import { getIsSubscriptionLoadingSelector } from "store/Reducers/loading.reducer";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import {
  getFoundingMemberSpotsLeftSelector,
  getSubscriptionProductsSelector,
  getSubscriptionStatusSelector,
} from "store/Reducers/subscription.reducer";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { globalNavigate } from "routes/GlobalRoutes";
import { SETTING_ROUTE } from "constants/Links";
import useConfig from "./config.hook";
import { auth } from "firebase-config";

const useSubscription = () => {
  const { handlePrimaryModal, getMemberStatusText } = useConfig();
  const dispatch = useDispatch();
  const isSubscriptionLoading = useSelector(getIsSubscriptionLoadingSelector);
  const subscriptionProducts = useSelector(getSubscriptionProductsSelector);
  const subscriptionStatus = useSelector(getSubscriptionStatusSelector);
  const foundingMemberSpotsLeft = useSelector(
    getFoundingMemberSpotsLeftSelector
  );
  const userProfile = useSelector(getUserProfileSelector);
  const [autoRenew, setAutoRenew] = useState(
    userProfile?.subscriptionCancelOnPeriodEnd ? false : true
  );
  const fetchSubscriptionProducts = () => {
    dispatch(fetchSubscriptionProductsAction());
  };

  const startCheckoutSession = useCallback((priceId: string) => {
    if (auth?.currentUser) {
      dispatch(createSubscriptionSession(priceId));
    } else {
      globalNavigate("/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile) {
      setAutoRenew(userProfile?.subscriptionCancelOnPeriodEnd ? false : true);
    }
  }, [userProfile]);

  const createStripePortalSession = () => {
    dispatch(createPortalSession());
  };

  const isUserBasicAndAbove = () => {
    if (
      (userProfile?.membershipStatus === "basic" ||
        userProfile?.membershipStatus === "premium" ||
        userProfile?.membershipStatus === "vip") &&
      userProfile.subscriptionStatus === "active"
    ) {
      return true;
    } else {
      showToast({
        type: ToastTypes.WARN,
        message:
          "You must be a basic, premium or vip member to access this feature.",
      });
      return false;
    }
  };

  const isUserPremiumAndAbove = () => {
    if (
      (userProfile?.membershipStatus === "premium" ||
        userProfile?.membershipStatus === "vip") &&
      userProfile.subscriptionStatus === "active"
    ) {
      return true;
    } else {
      showToast({
        type: ToastTypes.WARN,
        message: "You must be a premium or vip member to access this feature.",
      });
      return false;
    }
  };
  const navigateToPricing = () => {
    globalNavigate(`${SETTING_ROUTE}/pricingList`);
  };

  const haveAccessToContest = (contestMembershipLevel: string[]) => {
    const userLevel = userProfile?.membershipStatus
      ? userProfile?.membershipStatus
      : "free";
    if (
      contestMembershipLevel.findIndex(
        (level) => level.toLowerCase() === userLevel.toLowerCase()
      ) !== -1
    ) {
      return true;
    } else {
      showToast({
        type: ToastTypes.WARN,
        message:
          "You must be on one of the following membership levels to access this contest: " +
          contestMembershipLevel.join(", ") +
          ".",
      });
      navigateToPricing();
      return false;
    }
  };

  const stopAutoRenew = () => {
    if (autoRenew === true) {
      const membership = getMemberStatusText(
        userProfile?.membershipStatus || "FREE"
      );
      handlePrimaryModal({
        title: `Are you sure you want to stop your subscription? You will still be able to access ${membership} features until the end of your subscription date`,
        type: "Subscription",
        onCancelPressed: () => handlePrimaryModal(null),
        onSubmitPressed: () => {
          dispatch(updateAutoRenew(false, setAutoRenew));
          handlePrimaryModal(null);
        },
        submitText: "Disable Auto Renew",
        cancelText: "Cancel",
      });
    } else {
      dispatch(updateAutoRenew(true, setAutoRenew));
    }
  };

  return {
    startCheckoutSession,
    isSubscriptionLoading,
    subscriptionProducts,
    subscriptionStatus,
    createStripePortalSession,
    foundingMemberSpotsLeft,
    isUserPremiumAndAbove,
    navigateToPricing,
    haveAccessToContest,
    isUserBasicAndAbove,
    fetchSubscriptionProducts,
    autoRenew,
    setAutoRenew,
    stopAutoRenew,
  };
};

export default useSubscription;
