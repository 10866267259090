import { db, auth, getAllSpots } from "firebase-config";
import { SpotModel } from "types/Models";
import { Spotting } from "constants/Collections";
import { collection, doc, setDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import { SPOT_STATUS } from "types/Functions";

interface SpottingServiceType {
  createSpot(data: SpotModel): Promise<SpotModel | null>;
  fetchSpot(data: any): Promise<SpotModel[] | null>;
  editSpot(data: any): Promise<SpotModel | null>;
  checkExistingSpot(callSign: string): Promise<SpotModel | null>;
  markSpotAsInactive(spotId: string, deactivatedBy: string): Promise<void>;
}

const SpottingService: SpottingServiceType = {
  createSpot: async (data) => {
    const uid: string | undefined = auth.currentUser?.uid;
    if (uid) {
      const spotRef = doc(collection(db, Spotting));
      try {
        await setDoc(spotRef, data);
        return { id: spotRef.id, ...data };
      } catch (e) {
        console.error("createSpot ERROR : ", e);
        return null;
      }
    } else {
      return null;
    }
  },
  fetchSpot: async (data) => {
    try {
      let spots: SpotModel[] = [];
      const getSpots: { data: any } = await getAllSpots(data);

      if (getSpots && getSpots.data && Array.isArray(getSpots.data)) {
        spots = getSpots.data as SpotModel[];
      }
      return spots;
    } catch (e) {
      return null;
    }
  },
  editSpot: async (data) => {
    try {
      const spotRef = doc(db, Spotting, `${data.id}`);
      await setDoc(spotRef, data, { merge: true });
      return data;
    } catch (e) {
      return null;
    }
  },
  // Function to check for an existing spot
  checkExistingSpot: async (callSign: string): Promise<SpotModel | null> => {
    try {
      const spotsRef = collection(db, Spotting);
      const q = query(
        spotsRef,
        where('callSign', '==', callSign),
        where('status', '==', SPOT_STATUS.ACTIVE),
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        const existingSpot = docSnapshot.data() as SpotModel;
        existingSpot.id = docSnapshot.id;
        return existingSpot;
      }
      return null;
    } catch (error) {
      console.error('Error checking existing spot:', error);
      return null;
    }
  },
  // Function to mark a spot as inactive
  markSpotAsInactive: async (spotId: string, deactivatedBy: string): Promise<void> => {
    try {
      const spotRef = doc(db, Spotting, spotId);
      await updateDoc(spotRef, {
        status: SPOT_STATUS.INACTIVE,
        deactivatedBy,
      });
    } catch (error) {
      console.error('Error marking spot as inactive:', error);
    }
  },
};

export default SpottingService;
