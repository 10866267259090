import Header from "components/Header";
import RouteLoading from "components/Loading/RouteLoading";
import useLogBook from "hooks/logBook.hook";
import EmptyLogBook from "pages/Dashboard/LogBook/EmptyLogBook";
import LogBooksList from "pages/Dashboard/LogBook/LogBooksList";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchRadios,
  fetchAntennas,
  fetchStations,
} from "store/Actions/logBook.actions";
import { SortConfig } from "types/Models";

function LogBooks() {
  const dispatch = useDispatch();
  const {
    getLogBooks,
    isLoading,
    logBooksList,
    isLogContactForAdifLoading,
    userLogbookListener,
    userLogbookCollectionListner,
  } = useLogBook();
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    column: "",
    order: "",
  });
  useEffect(() => {
    // getLogBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const unsbscribe = userLogbookListener();
    const unsubscriveLogbookCollection =
      userLogbookCollectionListner(sortConfig);
    return () => {
      if (unsbscribe) {
        unsbscribe();
      }
      if (unsubscriveLogbookCollection) {
        unsubscriveLogbookCollection();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortConfig]);

  useEffect(() => {
    dispatch(fetchRadios());
    dispatch(fetchAntennas());
    dispatch(fetchStations());
  }, [dispatch]);

  const getLayout = () => {
    if (isLoading && logBooksList.length === 0) {
      return (
        <div className="dashboardWrapper">
          <RouteLoading />
        </div>
      );
    } else if (isLogContactForAdifLoading) {
      return (
        <div className="dashboardWrapper">
          <RouteLoading
            text="Exporting"
            subtext="Might take a minute or two if you have a large collection."
          />
        </div>
      );
    } else if (!isLoading && logBooksList.length === 0) {
      return <EmptyLogBook />;
    } else {
      return (
        <LogBooksList
          logBooksList={logBooksList}
          isLoading={isLoading}
          setSortConfig={setSortConfig}
        />
      );
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Logbook" />
      {getLayout()}
    </main>
  );
}

export default LogBooks;
