import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import TitledDefaultCountryPicker from "components/Inputs/TitledDefaultCountryPicker";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import { LocationMappings } from "constants/LocationMappings";
import ReactCountryFlag from "react-country-flag";
import { findCountryCode, latLonFromGrid } from "helpers/Utils";
import { VerticalDivider } from "components/Divider";
import { ParkReferenceModel, UserProfile } from "types/Models";
import { LocationSource } from "components/DefaultLocationSettings";
import TitledInput from "components/Inputs/TitledInput";
import { Form, Formik } from "formik";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";

type ModalProps = {
  onClose: () => void;
};

export const LocationModal: FC<ModalProps> = ({ onClose }) => {
  const {
    selectedLogbook,
    savedLocationOptions,
    locationForm,
    setLocationForm,
    locationLoading,
    findLocation,
    locationOption,
    setLocationOption,
    saveLogbookSettings,
  } = useLogbookSetting();

  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);

  const [defaultLocation, setDefaultLocation] = useState<string>(
    selectedLogbook?.defaultLocation?.id || userProfile?.savedLocationId || ""
  );

  const [canEdit, setCanEdit] = useState(true);
  const [locationSource, setLocationSource] = useState(LocationSource.NONE);

  useEffect(() => {
    if (selectedLogbook && selectedLogbook?.defaultLocation?.isCustom) {
      setLocationOption(2);
    }
  }, [selectedLogbook]);

  const onSaveClickHandler = () => {
    let location = null;

    if (locationOption === 1) {
      location = defaultLocation;
    } else {
      location = {
        isCustom: true,
        country: locationForm?.locationCountry,
        state: locationForm?.locationState,
        qth: locationForm?.locationQTH || "",
        lat: parseFloat(locationForm?.locationLat),
        lng: parseFloat(locationForm?.locationLong),
        gridsquare: locationForm?.locationGrid,
        id: "",
        locationType: "",
        default: false,
        name: "",
        uid: "",
        locationSource: locationSource || "",
      };
    }

    saveLogbookSettings({
      defaultLocation: location as any,
    });

    onClose();
  };

  const onEditClickHandler = () => {
    setCanEdit(false);
  };

  const setLocationFromPark = (park: ParkReferenceModel) => {
    setLocationForm((prev) => ({
      ...prev,
      locationCountry: park?.entityName || "",
      locationState: park?.locationName || "",
      locationGrid: park?.grid6 || "",
      locationLat: park?.latitude ? `${park?.latitude}` : "",
      locationLong: park?.longitude ? `${park?.longitude}` : "",
    }));
    setLocationSource(LocationSource.PARK);
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[14%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full bg-[#151541] text-white font-[Play] relative mt-20 border border-[#451836] px-[10%] md:px-[6%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col gap-4 w-full">
                <SelectableButtonsGroup
                  initialSelected={
                    selectedLogbook?.defaultLocation?.isCustom ? 2 : 1
                  }
                  actions={[
                    {
                      id: 1,
                      label: "Saved Location",
                    },
                    {
                      id: 2,
                      label: "Custom Location",
                    },
                  ]}
                  onButtonChange={(action) => {
                    setLocationOption((action?.id || 1) as number);
                  }}
                />
                <div className="flex flex-row flex-wrap">
                  {locationOption === 1 ? (
                    <LogbookSettingDropdown
                      list={savedLocationOptions}
                      title="Saved Locations:"
                      value={defaultLocation}
                      setValue={(value) => {
                        if (value === "add") {
                          globalNavigate("/log-contacts/saved-locations");
                        } else {
                          setDefaultLocation(value);
                        }
                      }}
                      className="w-[80%] sm:w-[60%] md:w-[60%]"
                    />
                  ) : null}

                  {locationOption === 2 ? (
                    <>
                      {canEdit ? (
                        <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-full">
                          <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                            <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                              <div className="flex flex-row items-center gap-1">
                                <div>
                                  {locationForm?.locationCountry ? (
                                    <ReactCountryFlag
                                      countryCode={
                                        findCountryCode({
                                          name:
                                            locationForm?.locationCountry || "",
                                        })?.alpha2
                                      }
                                      svg
                                      style={{
                                        width: "3em",
                                        height: "1.2em",
                                      }}
                                      title={
                                        locationForm?.locationCountry || ""
                                      }
                                    />
                                  ) : null}
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">Country:</div>
                                  <div className=" max-w-[100px]">
                                    {locationForm?.locationCountry}
                                  </div>
                                </div>
                              </div>
                              <VerticalDivider />
                              <div className="flex flex-row items-center gap-3">
                                <div>
                                  <img src={Icons.MapIcon} alt="" />
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">State:</div>
                                  <div>{locationForm?.locationState}</div>
                                </div>
                              </div>
                              <VerticalDivider />
                              <div className="flex flex-row items-center gap-3">
                                <div>
                                  <img src={Icons.GridsIcon} alt="" />
                                </div>
                                <div className="flex flex-col gap-0 font-['Barlow'] text-sm">
                                  <div className="font-bold">Grid:</div>
                                  <div>{locationForm?.locationGrid}</div>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center gap-4">
                              <VerticalDivider />
                              <img
                                className="cursor-pointer"
                                src={Icons.EditPenIcon}
                                alt=""
                                onClick={onEditClickHandler}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col w-full">
                          <div className="flex flex-row text-white px-3 py-1 bg-[#0D1E5D] gap-6  overflow-x-auto whitespace-nowrap">
                            <div
                              className="flex flex-row gap-2 cursor-pointer"
                              onClick={() => findLocation()}
                            >
                              <img src={Icons.MyLocation} alt="" />
                              <div className="text-sm font-Play">
                                Find My Location
                              </div>
                            </div>

                            {/* <div className="flex flex-row gap-2">
                              <img src={Icons.MapCyan} alt="" />
                              <div className="text-sm font-Play">
                                Set on Map
                              </div>
                            </div> */}

                            {Object.values(selectedLogbook?.myParks || {}).map(
                              (park, index) => (
                                <div
                                  className="flex flex-row gap-2 cursor-pointer"
                                  key={index}
                                  onClick={() => setLocationFromPark(park)}
                                >
                                  <img src={Icons.ParkCyan} alt="" />
                                  <div className="text-sm font-Play">
                                    {park?.reference}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                          <div className="flex bg-gradient-to-r from-blue-600 via-cyan-500 to-slate-800 rounded-[15px] pb-[1px] w-full">
                            <div className="flex flex-row justify-between gap-4 bg-[#161850] px-4 py-6 pr-8 rounded-[15px] w-full">
                              <div className="flex flex-col md:flex-row gap-2 md:gap-4 w-full">
                                <div className="flex flex-col lg:flex-row items-center gap-1">
                                  <Formik
                                    initialValues={{}}
                                    onSubmit={() => {}}
                                  >
                                    <Form className="flex flex-col lg:flex-row items-center gap-1">
                                      <TitledDefaultCountryPicker
                                        setText={(val) => {
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationCountry: val,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="country"
                                        title="Country"
                                        placeHolder="Country"
                                        value={
                                          LocationMappings[
                                            `${locationForm?.locationCountry}`
                                          ] || locationForm?.locationCountry
                                        }
                                        className={
                                          "bg-white bg-opacity-10 h-[36px]"
                                        }
                                        tabindex={8}
                                        width="w-[80%] lg:w-[22%]"
                                        error={undefined}
                                        containerClass="mb-[20px]"
                                      />
                                      {locationForm?.locationCountry ? (
                                        <TitledDefaultRegionPicker
                                          setText={(val) => {
                                            setLocationForm((prev) => ({
                                              ...prev,
                                              locationState: val,
                                            }));
                                            setLocationSource(
                                              LocationSource.CUSTOM
                                            );
                                          }}
                                          name="state"
                                          title="State"
                                          placeHolder="State"
                                          tabindex={9}
                                          width="w-[80%] lg:w-[22%]"
                                          className={
                                            "bg-white bg-opacity-10 h-[36px]"
                                          }
                                          value={locationForm?.locationState}
                                          error={undefined}
                                          country={
                                            LocationMappings[
                                              `${locationForm?.locationCountry}`
                                            ] || locationForm?.locationCountry
                                          }
                                          containerClass="mb-[20px]"
                                        />
                                      ) : null}
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationGrid: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="grid"
                                        title="Grid"
                                        value={locationForm?.locationGrid}
                                        placeHolder="Grid"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[15%]"
                                        onBlur={() => {
                                          if (
                                            locationForm.locationGrid &&
                                            locationForm.locationGrid.length > 0
                                          ) {
                                            const latLng = latLonFromGrid(
                                              locationForm.locationGrid
                                            );
                                            setLocationForm((prev) => ({
                                              ...prev,
                                              locationLat:
                                                latLng?.latitude as any,
                                              locationLong:
                                                latLng?.longitude as any,
                                            }));
                                          }
                                        }}
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationLat: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="latitude"
                                        title="Latitude"
                                        value={locationForm?.locationLat}
                                        placeHolder="latitude"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[16%]"
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                      <TitledInput
                                        setText={(e) => {
                                          const value = e.target.value;
                                          setLocationForm((prev) => ({
                                            ...prev,
                                            locationLong: value,
                                          }));
                                          setLocationSource(
                                            LocationSource.CUSTOM
                                          );
                                        }}
                                        name="longitude"
                                        title="Longitude"
                                        value={locationForm?.locationLong}
                                        placeHolder="longitude"
                                        className="bg-white bg-opacity-10 h-[36px]"
                                        width="w-[80%] lg:w-[16%]"
                                        // error={
                                        //   errors?.band && touched?.band ? errors?.band : undefined
                                        // }
                                        containerClass=""
                                      />
                                    </Form>
                                  </Formik>
                                </div>
                                <div className="flex items-center justify-center gap-4">
                                  <VerticalDivider />
                                  {locationLoading ? (
                                    <ActivityIndicator size={16} />
                                  ) : (
                                    <img
                                      className="cursor-pointer"
                                      src={Icons.SaveIcon}
                                      alt=""
                                      onClick={() => setCanEdit(true)}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={locationLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
