import { useState } from 'react';
import { SPOT_ACTION_TYPE } from 'types/Functions';

interface SpottingActionsProps {
    saveSpot: (actionType: SPOT_ACTION_TYPE) => Promise<boolean>;
}

export function useSpottingActions({ saveSpot }: SpottingActionsProps) {
    // State management
    const [isLoading, setIsLoading] = useState(false);
    const [successState, setSuccessState] = useState(false);

    /**
     * Handles the spot saving action with loading and success states
     * @param actionType The type of spotting action to save
     */
    const handleSaveSpot = async (actionType: SPOT_ACTION_TYPE) => {
        if (isLoading) {
            return; // Prevent concurrent saves
        }

        try {
            setIsLoading(true);
            const success = await saveSpot(actionType);
            setSuccessState(success);
        } catch (error) {
            console.error('Error saving spot:', error);
            setSuccessState(false);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Resets the success state to its initial value
     */
    const resetSuccessState = () => setSuccessState(false);

    return {
        isLoading,
        successState,
        handleSaveSpot,
        resetSuccessState,
    };
}